import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Chip,
  chipClasses,
  Divider,
  Grid,
  Skeleton,
  Stack,
  SvgIcon,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  RfqStatus,
  RfqDetail,
  useResponseUpdateOrder,
  OrderFeedbackConfirmStatus,
} from '../../../../services/brokers';
import { ModalLayout, useToast } from 'ui/components';
import ActionFetchRfqDetail from '../../components/ActionFetchRfqDetail';
import TypographyWithLoading from '../../components/TypographyWithLoading';
import ChatIcon from 'ui/icons/chat.svg?react';
import { thousandSeparated } from 'ui/utils';
import ActionOpenChatOfRfq from '../../../../libs/ui/components/Chat/ActionOpenChatOfRfq';
import dayjs from 'dayjs';
import BaseActionDialog from 'ui/components/modal/BaseActionDialog';
import RfqHeader from './RfqHeader';
import DetailLabel from '../../components/DetailLabel';
import ColumnStatus from './ColumnStatus';
import ColumnSize from '../../components/ColumnSize';
import ColumnDirection from '../../components/ColumnDirection';
import ColumnClientAccount from '../../components/ColumnClientAccount';
import ColumnQuoteTime from '../../components/ColumnQuoteTime';
import ColumnRequestTime from '../../components/ColumnRequestTime';
import ColumnSettlementDate from '../../components/ColumnSettlementDate';
import { useMeasure } from 'react-use';
import { formatApiErrorToast } from '../../../../services/apiUtils';

const ActionResponseOrderUpdate: React.FC<{
  children: (props: { onClick: () => void; disabled?: boolean }) => JSX.Element;
  onSuccess?: () => void;
  onOpen?: () => void;
  onClose?: () => void;
  rfq: RfqDetail;
}> = ({ children, onSuccess, onOpen, onClose: onCloseProp, rfq }) => {
  const { t } = useTranslation('rfq');
  const theme = useTheme();
  const onMobile = useMediaQuery(theme.breakpoints.down('md'));
  const onTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const [open, setOpen] = useState(false);
  const toast = useToast();
  const [headerContainerRef, { height: headerHeight }] = useMeasure();

  const { mutate, isLoading } = useResponseUpdateOrder({
    onSuccess: (data, variables) => {
      switch (variables?.status) {
        case OrderFeedbackConfirmStatus.Confirmed:
          toast.success(t('title.confirmOrderUpdateSuccess'));
          break;
        case OrderFeedbackConfirmStatus.Rejected:
          toast.success(
            t('title.rejectOrderUpdateSuccess'),
            t('message.rejectOrderUpdateSuccess'),
          );
          break;
      }

      setOpen(false);
      onSuccess?.();
    },
    onError: (err) => {
      const error = err?.response?.data?.error;
      toast.error(...formatApiErrorToast(t, error?.message));
    },
  });

  const onConfirm = () => {
    mutate({
      rfqId: rfq.id.toString(),
      orderId: rfq.orderId.toString(),
      status: OrderFeedbackConfirmStatus.Confirmed,
      executedAt: dayjs().format('YYYY-MM-DD hh:mm:ss'),
    });
  };

  const onReject = () => {
    mutate({
      rfqId: rfq.id.toString(),
      orderId: rfq.orderId.toString(),
      status: OrderFeedbackConfirmStatus.Rejected,
      executedAt: dayjs().format('YYYY-MM-DD hh:mm:ss'),
    });
  };

  const handleClick = () => {
    setOpen(true);
  };
  const onClose = () => {
    if (isLoading) return;
    setOpen(false);
    onMobile
      ? setTimeout(() => {
          onCloseProp?.();
        }, theme.transitions.duration.standard)
      : onCloseProp?.();
  };

  const renderActions = useCallback(
    (rfq: RfqDetail) => (
      <Stack
        direction={{
          xs: 'column',
          lg: 'row',
        }}
        justifyContent={{
          xs: 'flex-start',
          lg: 'space-between',
        }}
        spacing={{
          xs: 2,
          lg: 0,
        }}
      >
        {!onTablet && (
          <>
            <Box>
              {rfq?.traderName && (
                <ActionOpenChatOfRfq
                  username={rfq?.traderName}
                  rfq={rfq}
                  onSuccess={() => {
                    onClose();
                  }}
                >
                  {(props) => (
                    <Button
                      variant={'outlined'}
                      color={'primary'}
                      size="large"
                      startIcon={
                        <SvgIcon>
                          <ChatIcon />
                        </SvgIcon>
                      }
                      {...props}
                      fullWidth
                    >
                      {t('action.openChat')}
                    </Button>
                  )}
                </ActionOpenChatOfRfq>
              )}
            </Box>
            <Stack
              direction={'row'}
              justifyContent={{
                xs: 'space-between',
                md: 'flex-end',
              }}
              spacing={2}
            >
              <Button
                variant={'outlined'}
                color={'primary'}
                size="large"
                disabled={isLoading}
                onClick={onReject}
                fullWidth
              >
                {t('action.rejectOrderUpdate')}
              </Button>
              <Button
                variant="contained"
                size="large"
                disabled={isLoading}
                onClick={onConfirm}
                fullWidth
              >
                {t('action.confirmOrderUpdate')}
              </Button>
            </Stack>
          </>
        )}
        {onTablet && (
          <Box>
            <Grid container spacing={2}>
              {rfq?.traderName && (
                <Grid item xs={6}>
                  <ActionOpenChatOfRfq
                    username={rfq?.traderName}
                    rfq={rfq}
                    onSuccess={() => {
                      onClose();
                    }}
                  >
                    {(props) => (
                      <Button
                        variant={'outlined'}
                        color={'primary'}
                        size="large"
                        startIcon={
                          <SvgIcon>
                            <ChatIcon />
                          </SvgIcon>
                        }
                        {...props}
                        fullWidth
                      >
                        {t('action.openChat')}
                      </Button>
                    )}
                  </ActionOpenChatOfRfq>
                </Grid>
              )}
              <Grid item xs={6}>
                <Button
                  variant={'outlined'}
                  color={'primary'}
                  size="large"
                  disabled={isLoading}
                  onClick={onReject}
                  fullWidth
                >
                  {t('action.rejectOrderUpdate')}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  size="large"
                  disabled={isLoading}
                  onClick={onConfirm}
                  fullWidth
                >
                  {t('action.confirmOrderUpdate')}
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
      </Stack>
    ),
    [isLoading, t],
  );
  const renderContent = useCallback(
    () => (
      <>
        <Box ref={headerContainerRef}>
          <RfqHeader rfq={rfq} subheader={t('tooltip.acceptQuote')} />
        </Box>
        <Divider />
        <Box
          pl={{
            xs: 2,
            lg: 8,
          }}
          pr={{
            xs: 2,
            lg: 4,
          }}
          py={{
            xs: 2,
            lg: 4,
          }}
          maxHeight={{
            xs: `calc(90dvh - ${headerHeight}px - 4px)`,
            md: 'unset',
          }}
          overflow={{
            xs: 'auto',
            md: 'unset',
          }}
        >
          <ActionFetchRfqDetail rfqId={rfq?.id} view={0} enabled={open}>
            {({ data, isLoading: isLoadingData }) => (
              <>
                <Grid
                  container
                  rowSpacing={{
                    xs: 2,
                    md: 4,
                  }}
                  columnSpacing={2}
                >
                  {onMobile && (
                    <Grid item xs={12} md={4}>
                      <DetailLabel>{t('field.status')}</DetailLabel>
                      <TypographyWithLoading loading={isLoadingData}>
                        <ColumnStatus rfq={data} />
                      </TypographyWithLoading>
                    </Grid>
                  )}
                  <Grid item xs={6} md={4}>
                    <DetailLabel>{t('field.size')}</DetailLabel>
                    <TypographyWithLoading loading={isLoadingData}>
                      <ColumnSize rfq={data} />
                    </TypographyWithLoading>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <DetailLabel>{t('field.price')}</DetailLabel>
                    <TypographyWithLoading loading={isLoadingData}>
                      {data?.quote ? (
                        data?.price && data?.quote !== data?.price ? (
                          <Stack
                            direction={{
                              xs: 'column',
                              lg: 'row',
                            }}
                            spacing={1}
                          >
                            <Stack direction={'row'} spacing={1}>
                              <Chip
                                label={t('common:option.old')}
                                size="small"
                                sx={{
                                  textTransform: 'uppercase',
                                  [`& .${chipClasses.root}`]: {
                                    backgroundColor: '#232525',
                                  },
                                  [`& .${chipClasses.label}`]: {
                                    color: 'text.secondary',
                                  },
                                }}
                              />
                              <Typography
                                color={'textSecondary'}
                                component={'span'}
                                sx={{
                                  textDecoration: 'line-through',
                                }}
                              >
                                {thousandSeparated(data?.price)}
                              </Typography>
                            </Stack>
                            <Stack direction={'row'} spacing={1}>
                              <Chip
                                label={t('common:option.new')}
                                color="primary"
                                size="small"
                                sx={{
                                  textTransform: 'uppercase',
                                }}
                              />
                              <Typography component={'span'}>
                                {thousandSeparated(data?.quote)}
                              </Typography>
                            </Stack>
                          </Stack>
                        ) : (
                          thousandSeparated(data?.price)
                        )
                      ) : (
                        '-'
                      )}
                    </TypographyWithLoading>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <DetailLabel>{t('field.settlementDate')}</DetailLabel>
                    <TypographyWithLoading loading={isLoadingData}>
                      <ColumnSettlementDate rfq={data} />
                    </TypographyWithLoading>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <DetailLabel>{t('field.direction')}</DetailLabel>
                    {isLoadingData ? (
                      <Skeleton variant="text" width={100} />
                    ) : (
                      <ColumnDirection rfq={data} variant="chip" />
                    )}
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <DetailLabel>{t('bond:field.isin')}</DetailLabel>
                    <TypographyWithLoading loading={isLoadingData}>
                      {data?.assetIdentifier || '-'}
                    </TypographyWithLoading>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <DetailLabel>
                      {t('bond:field.tradingRestrictionType')}
                    </DetailLabel>
                    <TypographyWithLoading loading={isLoadingData}>
                      {data?.bond?.tradingRestrictionType || '-'}
                    </TypographyWithLoading>
                  </Grid>
                  {rfq.status === RfqStatus.OrderExecuted && (
                    <Grid item xs={6} md={4}>
                      <DetailLabel>{t('field.orderAccount')}</DetailLabel>
                      <TypographyWithLoading loading={isLoadingData}>
                        <ColumnClientAccount rfq={data} />
                      </TypographyWithLoading>
                    </Grid>
                  )}
                  <Grid item xs={6} md={4}>
                    <DetailLabel>{t('field.quotedAt')}</DetailLabel>
                    <TypographyWithLoading loading={isLoadingData}>
                      <ColumnQuoteTime rfq={data} />
                    </TypographyWithLoading>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <DetailLabel>{t('field.id')}</DetailLabel>
                    <TypographyWithLoading loading={isLoadingData}>
                      {data?.id ?? '-'}
                    </TypographyWithLoading>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <DetailLabel>{t('field.orderedAt')}</DetailLabel>
                    <TypographyWithLoading loading={isLoadingData}>
                      <ColumnRequestTime rfq={data} />
                    </TypographyWithLoading>
                  </Grid>
                  {!onMobile && data && (
                    <Grid item xs={12}>
                      {renderActions(data)}
                    </Grid>
                  )}
                </Grid>
                {onMobile && data && (
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      right: 16,
                      pl: 2,
                      pb: 2,
                      bgcolor: (theme) => theme.palette.background.default,
                    }}
                  >
                    {renderActions(data)}
                  </Box>
                )}
              </>
            )}
          </ActionFetchRfqDetail>
          {onMobile && <Box height={160} />}
        </Box>
      </>
    ),
    [rfq, t, open, onMobile, renderActions, headerHeight],
  );

  useEffect(() => {
    if (open) {
      onOpen?.();
    }
  }, [open]);

  if (onMobile) {
    return (
      <>
        {children({ onClick: handleClick })}
        <ModalLayout open={open} onClose={onClose}>
          {renderContent()}
        </ModalLayout>
      </>
    );
  }

  return (
    <>
      {children({ onClick: handleClick })}
      <BaseActionDialog
        open={open}
        onClose={onClose}
        width={{
          xs: '90%',
          md: '80%',
          lg: 976,
        }}
      >
        {renderContent()}
      </BaseActionDialog>
    </>
  );
};

export default ActionResponseOrderUpdate;
