import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Divider,
  Grid,
  Skeleton,
  Stack,
  SvgIcon,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { RfqStatus, useAddRFQ } from '../../../../services/brokers';
import ReactGA from 'react-ga4';
import { EventAction } from 'analytics/ga4';
import { ModalLayout, useToast } from 'ui/components';
import ActionFetchRfqDetail from '../../components/ActionFetchRfqDetail';
import TypographyWithLoading from '../../components/TypographyWithLoading';
import ChatIcon from 'ui/icons/chat.svg?react';
import ActionOpenChatOfRfq from '../../../../libs/ui/components/Chat/ActionOpenChatOfRfq';
import { RfqDetail } from '../../../../services/brokers';
import BaseActionDialog from 'ui/components/modal/BaseActionDialog';
import RfqHeader from './RfqHeader';
import DetailLabel from '../../components/DetailLabel';
import ColumnStatus from './ColumnStatus';
import ColumnSize from '../../components/ColumnSize';
import ColumnPrice from './ColumnPrice';
import ColumnDirection from '../../components/ColumnDirection';
import ColumnClientAccount from '../../components/ColumnClientAccount';
import ColumnQuoteTime from '../../components/ColumnQuoteTime';
import ColumnRequestTime from '../../components/ColumnRequestTime';
import ColumnSettlementDate from '../../components/ColumnSettlementDate';
import { useMeasure } from 'react-use';
import { formatApiErrorToast } from '../../../../services/apiUtils';

const ActionOrderDetail: React.FC<{
  children: (props: { onClick: () => void; disabled?: boolean }) => JSX.Element;
  onSameOrderRequested?: () => void;
  onOpen?: () => void;
  onClose?: () => void;
  rfq: RfqDetail;
}> = ({
  children,
  onSameOrderRequested,
  onOpen,
  onClose: onCloseProp,
  rfq,
}) => {
  const { t } = useTranslation('rfq');
  const theme = useTheme();
  const onMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = useState(false);
  const toast = useToast();
  const [headerContainerRef, { height: headerHeight }] = useMeasure();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        direction: yup
          .string()
          .required()
          .label(t('field.direction').toLowerCase()),
        size: yup.number().required().label(t('field.size').toLowerCase()),
      }),
    [t],
  );
  const { handleSubmit } = useForm({
    defaultValues: {
      assetId: rfq?.assetId,
      assetType: 'BOND',
      direction: rfq?.direction,
      size: rfq?.initSize,
      isPartialFill: rfq?.isPartialFill,
    },
    resolver: yupResolver(validationSchema),
  });
  const handleClick = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
    onMobile
      ? setTimeout(() => {
          onCloseProp?.();
        }, theme.transitions.duration.standard)
      : onCloseProp?.();
  };

  const { mutate: addRFQ, isLoading } = useAddRFQ({
    onSuccess(res) {
      ReactGA.event({
        action: EventAction.RequestSuccess,
        category: 'trade',
        label: 'action_request_for_quote_success',
      });
      toast.success(t('title.rfqCreated'), t('message.rfqCreated'));
      onSameOrderRequested?.();
      onClose();
    },
    onError(err) {
      const error = err?.response?.data;
      ReactGA.event({
        action: EventAction.RequestError,
        category: 'trade',
        label: 'action_request_for_quote_error',
      });

      toast.error(...formatApiErrorToast(t, error?.message));
    },
  });

  const onSubmit: SubmitHandler<{
    assetId: number;
    assetType: string;
    direction: string;
    size: number;
    isPartialFill: boolean;
  }> = async (data) => {
    addRFQ({
      assetId: data.assetId,
      assetType: data.assetType,
      direction: data.direction,
      size: data.size,
      isPartialFill: data.isPartialFill,
    });
  };

  const renderActions = useCallback(
    (data: RfqDetail) => (
      <Stack
        direction={{
          xs: 'column',
          sm: 'row',
        }}
        justifyContent={'flex-end'}
        spacing={2}
      >
        {data?.traderName && (
          <ActionOpenChatOfRfq
            username={data?.traderName}
            rfq={data}
            onSuccess={() => {
              onClose();
            }}
          >
            {(props) => (
              <Button
                variant={'outlined'}
                color={'primary'}
                size="large"
                startIcon={
                  <SvgIcon>
                    <ChatIcon />
                  </SvgIcon>
                }
                {...props}
              >
                {t('action.openChat')}
              </Button>
            )}
          </ActionOpenChatOfRfq>
        )}
        {[
          RfqStatus.Expired,
          RfqStatus.OrderExecuted,
          RfqStatus.OrderCancelledByClient,
          RfqStatus.OrderCancelledByBlotter,
          RfqStatus.ClientRejected,
        ].includes(rfq?.status) && (
          <Button
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            size="large"
            disabled={isLoading}
          >
            {t('action.requestOrderAgain')}
          </Button>
        )}
      </Stack>
    ),
    [isLoading, t],
  );
  const renderContent = useCallback(
    () => (
      <>
        <Box ref={headerContainerRef}>
          <RfqHeader
            rfq={rfq}
            subheader={
              [RfqStatus.OrderPlaced, RfqStatus.OrderExecuted].includes(
                rfq?.status,
              )
                ? t('tooltip.acceptQuote')
                : ''
            }
          />
        </Box>
        <Divider />
        <Box
          p={{
            xs: 2,
            lg: 4,
          }}
          maxHeight={{
            xs: `calc(90dvh - ${headerHeight}px - 4px)`,
            md: 'unset',
          }}
          overflow={{
            xs: 'auto',
            md: 'unset',
          }}
        >
          <ActionFetchRfqDetail rfqId={rfq?.id} view={0} enabled={open}>
            {({ data, isLoading: isLoadingData }) => (
              <>
                <Grid
                  container
                  rowSpacing={{
                    xs: 2,
                    md: 4,
                  }}
                  columnSpacing={2}
                >
                  {onMobile && (
                    <Grid item xs={6} md={4}>
                      <DetailLabel>{t('field.status')}</DetailLabel>
                      <TypographyWithLoading loading={isLoadingData}>
                        <ColumnStatus rfq={data} />
                      </TypographyWithLoading>
                    </Grid>
                  )}
                  <Grid item xs={6} md={4}>
                    <DetailLabel>{t('field.size')}</DetailLabel>
                    <TypographyWithLoading loading={isLoadingData}>
                      <ColumnSize rfq={data} />
                    </TypographyWithLoading>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <DetailLabel>{t('field.price')}</DetailLabel>
                    <TypographyWithLoading loading={isLoadingData}>
                      <ColumnPrice rfq={data} />
                    </TypographyWithLoading>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <DetailLabel>{t('field.settlementDate')}</DetailLabel>
                    <TypographyWithLoading loading={isLoadingData}>
                      <ColumnSettlementDate rfq={data} />
                    </TypographyWithLoading>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <DetailLabel>{t('field.direction')}</DetailLabel>
                    {isLoadingData ? (
                      <Skeleton variant="text" width={100} />
                    ) : (
                      <ColumnDirection rfq={data} variant="chip" />
                    )}
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <DetailLabel>{t('bond:field.isin')}</DetailLabel>
                    <TypographyWithLoading loading={isLoadingData}>
                      {data?.assetIdentifier || '-'}
                    </TypographyWithLoading>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <DetailLabel>
                      {t('bond:field.tradingRestrictionType')}
                    </DetailLabel>
                    <TypographyWithLoading loading={isLoadingData}>
                      {data?.bond?.tradingRestrictionType || '-'}
                    </TypographyWithLoading>
                  </Grid>
                  {rfq.status === RfqStatus.OrderExecuted && (
                    <Grid item xs={6} md={4}>
                      <DetailLabel>{t('field.orderAccount')}</DetailLabel>
                      <TypographyWithLoading loading={isLoadingData}>
                        <ColumnClientAccount rfq={data} />
                      </TypographyWithLoading>
                    </Grid>
                  )}
                  <Grid item xs={6} md={4}>
                    <DetailLabel>{t('field.quotedAt')}</DetailLabel>
                    <TypographyWithLoading loading={isLoadingData}>
                      <ColumnQuoteTime rfq={data} />
                    </TypographyWithLoading>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <DetailLabel>{t('field.id')}</DetailLabel>
                    <TypographyWithLoading loading={isLoadingData}>
                      {data?.id ?? '-'}
                    </TypographyWithLoading>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <DetailLabel>{t('field.orderedAt')}</DetailLabel>
                    <TypographyWithLoading loading={isLoadingData}>
                      <ColumnRequestTime rfq={data} />
                    </TypographyWithLoading>
                  </Grid>
                  {data?.orderNote && (
                    <Grid item xs={12}>
                      <DetailLabel>{t('field.cancelReason')}</DetailLabel>
                      <TypographyWithLoading loading={isLoadingData}>
                        {data?.orderNote ?? '-'}
                      </TypographyWithLoading>
                    </Grid>
                  )}
                  {!onMobile && data && (
                    <Grid item xs={12}>
                      {renderActions(data)}
                    </Grid>
                  )}
                </Grid>
                {onMobile && data && (
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      right: 16,
                      pl: 2,
                      pb: 2,
                      bgcolor: (theme) => theme.palette.background.default,
                    }}
                  >
                    {renderActions(data)}
                  </Box>
                )}
              </>
            )}
          </ActionFetchRfqDetail>
          {onMobile && <Box height={80} />}
        </Box>
      </>
    ),
    [rfq, t, open, isLoading, onMobile, headerHeight],
  );

  useEffect(() => {
    if (open) {
      onOpen?.();
    }
  }, [open]);

  if (onMobile) {
    return (
      <>
        {children({ onClick: handleClick })}
        <ModalLayout open={open} onClose={onClose}>
          {renderContent()}
        </ModalLayout>
      </>
    );
  }

  return (
    <>
      {children({ onClick: handleClick })}

      <BaseActionDialog
        open={open}
        onClose={onClose}
        width={{
          xs: '90%',
          md: '80%',
          lg: 976,
        }}
      >
        {renderContent()}
      </BaseActionDialog>
    </>
  );
};

export default ActionOrderDetail;
