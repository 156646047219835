import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  formLabelClasses,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import BaseActionDialog from 'ui/components/modal/BaseActionDialog';
import { useToast } from 'ui/components/snackbar/SnackbarBox';
import { isNil, upperCase } from 'lodash';
import { isDivisibleBy, thousandSeparated } from 'ui/utils';
import { LoadingCover } from 'ui/components';
import DialogCover from 'ui/icons/dialog/rfq-dialog-cover.svg?react';
import MobileDialogCover from 'ui/icons/dialog/rfq-mobile-dialog-cover.svg?react';
import {
  useAddRFQ,
  useIndex as useBrokerIndex,
} from '../../../../services/brokers';
import {
  Services,
  serviceIsDisabled,
} from '../../../../config/serviceIsDisabled';
import ReactGA from 'react-ga4';
import { EventAction, Events } from 'analytics/ga4';
import { useNavigate } from 'react-router-dom';
import qs from 'qs';
import { useJoinWaitList, useKyc } from '../../../../services/kyc';
import { useAssetBonds } from '../../../../services/bonds';
import { usePermittedV2 } from '../../../../libs/hooks/usePermissions';
import { RedirectActions } from '../../../../libs/layout/RedirectLoader';
import { NumericFormat } from 'react-number-format';
import { formatApiErrorToast } from '../../../../services/apiUtils';

interface FormValues {
  direction: string;
  size: number;
  isPartialFill: boolean;
}

export const useActionDisabled = () => {
  const [permittedToRequestQuote] = usePermittedV2('action.requestForQuote');

  if (serviceIsDisabled(Services.TRADE)) {
    return true;
  }

  if (!permittedToRequestQuote) {
    // Blotter side could not pull up RFQ
    return true;
  }

  return false;
};

const ActionRequestForQuote: React.FC<{
  children: (props: {
    onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    disabled?: boolean;
    'data-ga-event': 'action_request_for_quote_init';
    'data-ga-category': 'trade';
    'data-testid': 'action-request-for-quote';
  }) => JSX.Element;
  onCreated?: () => void;
  asset: {
    id: number;
    assetName: string;
    companyName: string;
  };
}> = ({ children, onCreated, asset }) => {
  const { t } = useTranslation(['rfq', 'validation', 'bond']);
  const [open, setOpen] = useState(false);
  const [openRequireBroker, setOpenRequireBroker] = useState(false);
  const [openRequireKyc, setOpenRequireKyc] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  const theme = useTheme();
  const onTablet = useMediaQuery(theme.breakpoints.down('lg'));

  const actionDisabled = useActionDisabled();

  const enabledFetchAsset = useMemo(() => {
    return !!asset?.id && open;
  }, [asset, open]);

  const { data: assetDetail, isLoading: isLoadingAssetDetail } = useAssetBonds(
    String(asset?.id),
    {
      enabled: enabledFetchAsset,
      keepPreviousData: true,
    },
  );
  const minIncrement = useMemo(() => {
    return assetDetail?.minIncrement ?? 0;
  }, [assetDetail]);
  const minSize = useMemo(() => {
    return assetDetail?.minSize ?? 0;
  }, [assetDetail]);

  const defaultValues = useMemo(
    () => ({
      direction: '',
      size: minSize,
      isPartialFill: true,
    }),
    [minSize],
  );
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        direction: yup.string().required().label(t('field.direction')),
        size: yup
          .number()
          .min(
            minSize || 0,
            t('validation:number.min', {
              label: t('field.size').toLowerCase(),
              min: thousandSeparated(minSize || 0),
            }),
          )
          .required()
          .label(t('field.size').toLowerCase())
          .test(
            'multipleOfAssetMinIncrement',
            t('validation:custom.multipleOfAssetMinIncrement', {
              minIncrement: thousandSeparated(minIncrement || 0),
            }),
            isDivisibleBy(minIncrement),
          ),
        isPartialFill: yup.boolean().required(),
      }),
    [minIncrement, minSize, t],
  );
  const methods = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const { control, handleSubmit, reset, formState } = methods;
  const { isValid } = formState;

  const { data: kycResult, isLoading: isLoadingKyc } = useKyc();
  const isKycPassed = useMemo(() => {
    return kycResult?.isPassed;
  }, [kycResult]);
  const alreadyJoinWaitList = useMemo(() => {
    return kycResult?.isWaiting;
  }, [kycResult]);

  const { data: brokerIndex, isLoading: isLoadingBrokerIndex } = useBrokerIndex(
    {
      page: 1,
      size: 10000,
      status: 3,
    },
  );
  const hasVerifiedBroker = useMemo(() => {
    return brokerIndex?.totalItems && brokerIndex?.totalItems > 0;
  }, [brokerIndex]);

  const { mutate: addRFQ, isLoading } = useAddRFQ({
    onSuccess(res) {
      ReactGA.event({
        action: EventAction.RequestSuccess,
        category: 'trade',
        label: 'action_request_for_quote_success',
      });
      toast.success(t('title.rfqCreated'), t('message.rfqCreated'), {
        onClick: () => {
          navigate(
            `/app/rfq/orders?${qs.stringify({
              action: RedirectActions.RfqCreated,
            })}`,
          );
        },
      });
      onCreated?.();
      onClose();
    },
    onError(error) {
      ReactGA.event({
        action: EventAction.RequestError,
        category: 'trade',
        label: 'action_request_for_quote_error',
      });
      toast.error(...formatApiErrorToast(t, error?.message));
    },
  });

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    !isNil(asset?.id) &&
      addRFQ({
        assetId: asset?.id,
        assetType: 'BOND',
        direction: data.direction,
        size: data.size,
        isPartialFill: data.isPartialFill,
      });
  };

  const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    Events.ButtonClick(e);

    if (!isKycPassed) {
      setOpenRequireKyc(true);
      return;
    }

    if (!serviceIsDisabled(Services.TRADE_BROKER) && !hasVerifiedBroker) {
      setOpenRequireBroker(true);
      return;
    }

    setOpen(true);
  };
  const onClose = () => {
    ReactGA.event({
      action: EventAction.ButtonClick,
      category: 'trade',
      label: 'action_request_for_quote_cancel',
    });
    reset();
    setOpen(false);
  };

  // join wait list
  const { mutate: joinWaitList, isLoading: isLoadingJoinWaitList } =
    useJoinWaitList({
      onSuccess() {
        setOpenRequireKyc(false);
        toast.success(
          t('title.joinWaitListSuccess'),
          t('message.joinWaitListSuccess'),
        );
      },
      onError(error) {
        toast.error(...formatApiErrorToast(t, error?.message));
      },
    });

  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues]);

  if (actionDisabled) {
    // Blotter side could not pull up RFQ
    return null;
  }

  return (
    <>
      {children({
        onClick: handleClick,
        disabled: isLoadingKyc || isLoadingBrokerIndex,
        'data-ga-event': 'action_request_for_quote_init',
        'data-ga-category': 'trade',
        'data-testid': 'action-request-for-quote',
      })}
      <BaseActionDialog
        open={open}
        onClose={onClose}
        cover={<DialogCover width={556} />}
        data-testid="dialog-request-for-quote"
        width={{
          xs: '90%',
          md: '80%',
          lg: 1112,
        }}
      >
        <Stack height={'100%'} justifyContent={'center'}>
          <Box pt={6} mb={2}>
            {onTablet && (
              <Box textAlign={'center'}>
                <MobileDialogCover width={90} />
              </Box>
            )}
            <Typography
              variant={onTablet ? 'body1' : 'h5'}
              className="uppercase py-2 font-bold text-center"
            >
              {t('title.requestForQuote')}
            </Typography>
            {asset && (
              <div className="text-center">
                <Typography variant={'body1'} fontWeight={'bold'} gutterBottom>
                  {asset.assetName}
                </Typography>
                <Typography
                  variant="body2"
                  color={'textSecondary'}
                  gutterBottom
                >
                  {asset.companyName}
                </Typography>
              </div>
            )}
          </Box>
          <Stack
            width={'100%'}
            px={4}
            pb={2}
            flex={1}
            justifyContent={'space-between'}
            spacing={2}
          >
            <Stack spacing={2}>
              <Controller
                name="direction"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl error={!!fieldState.error}>
                    <Stack
                      direction={{
                        xs: 'column',
                        sm: 'row',
                      }}
                      justifyContent={'space-between'}
                      alignItems={{
                        xs: 'flex-start',
                        sm: 'center',
                      }}
                    >
                      <FormLabel
                        sx={{
                          [`&.${formLabelClasses.root}.${formLabelClasses.focused}`]:
                            {
                              color: 'text.secondary',
                            },
                        }}
                      >
                        {t('field.direction')}
                      </FormLabel>
                      <RadioGroup {...field} row>
                        <FormControlLabel
                          value="SELL"
                          control={<Radio />}
                          label={upperCase(t('options.direction.sell'))}
                          sx={{
                            color: 'text.secondary',
                          }}
                        />
                        <FormControlLabel
                          value="BUY"
                          control={<Radio />}
                          label={upperCase(t('options.direction.buy'))}
                          sx={{
                            color: 'text.secondary',
                            mr: 0,
                          }}
                        />
                      </RadioGroup>
                    </Stack>
                    <FormHelperText>
                      {fieldState.error?.message ?? ''}
                    </FormHelperText>
                  </FormControl>
                )}
              />
              <Controller
                name="size"
                control={control}
                render={({ field, fieldState }) => (
                  <NumericFormat
                    thousandSeparator
                    customInput={TextField}
                    value={field.value}
                    onValueChange={(values) => {
                      const { floatValue } = values;
                      field.onChange(floatValue);
                    }}
                    fullWidth
                    label={t('field.size')}
                    error={!!fieldState.error}
                    placeholder={t('tooltip.size')}
                    helperText={
                      fieldState.error
                        ? fieldState.error?.message
                        : t('bond:tooltip.bondSize', {
                            minSize: thousandSeparated(minSize as number),
                            minIncrement: thousandSeparated(
                              minIncrement as number,
                            ),
                          })
                    }
                    disabled={isLoadingAssetDetail}
                  />
                )}
              />
              <Controller
                name="isPartialFill"
                control={control}
                render={({ field }) => (
                  <Box
                    sx={{
                      pl: 1,
                    }}
                  >
                    <FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...field}
                            color={'primary'}
                            checked={field.value}
                            sx={{
                              mr: 1,
                            }}
                          />
                        }
                        label={t('message.agreePartialFill')}
                        sx={{
                          alignItems: 'start',

                          mb: 2,
                        }}
                      />
                      <Typography variant={'body2'} color={'error'}>
                        {t('tooltip.partialFill')}
                      </Typography>
                    </FormControl>
                  </Box>
                )}
              />
            </Stack>

            <Stack spacing={1} textAlign={'center'}>
              <Button
                variant={isValid ? 'contained' : 'text'}
                onClick={(e) => {
                  Events.ButtonClick(e);
                  handleSubmit(onSubmit)();
                }}
                disabled={!isValid}
                size="large"
                data-ga-event="action_request_for_quote_submit"
                data-ga-category="trade"
                data-testid="action-request-for-quote-submit"
              >
                {t('action.requestForQuote')}
              </Button>
              {isValid && (
                <Typography variant={'body2'} color={'textSecondary'}>
                  {t('message.rfqDisclaimer')}
                </Typography>
              )}
            </Stack>
          </Stack>
        </Stack>
        <LoadingCover loading={isLoading} />
      </BaseActionDialog>
      {/* Suggestions for user to add broker */}
      <BaseActionDialog
        open={openRequireBroker}
        onClose={() => setOpenRequireBroker(false)}
      >
        <Box pt={8}>
          <div className="text-center">
            <Typography
              variant={onTablet ? 'body1' : 'h5'}
              className="uppercase py-2 font-bold"
            >
              {t('action.requireBroker')}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {t('tooltip.requireBroker')}
            </Typography>
          </div>
        </Box>
        <Stack
          width={'100%'}
          p={2}
          flex={1}
          justifyContent={'space-between'}
          spacing={2}
          direction={'row'}
        >
          <Button
            variant="contained"
            onClick={() => {
              setOpenRequireBroker(false);
              navigate({
                pathname: '/app/settings/account/broker',
              });
            }}
            size={'large'}
            sx={{
              flex: 1,
            }}
            disabled={isLoading}
          >
            {t('action.addBroker')}
          </Button>
        </Stack>
      </BaseActionDialog>
      {/* By the mean time, the KYC function is not enabled,
      so if user's KYC hadn't been toggled from CMS, the user can only select to join wait list for future features */}
      <BaseActionDialog
        open={openRequireKyc}
        onClose={() => setOpenRequireKyc(false)}
      >
        <Box pt={8}>
          <div className="text-center">
            <Typography
              variant={onTablet ? 'body1' : 'h5'}
              className="uppercase py-2 font-bold"
            >
              {alreadyJoinWaitList
                ? t('action.alreadyJoinWaitList')
                : t('action.joinWaitList')}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {t('tooltip.joinWaitList')}
            </Typography>
          </div>
        </Box>
        <Stack
          width={'100%'}
          p={2}
          flex={1}
          justifyContent={'space-between'}
          spacing={2}
          direction={'row'}
        >
          {alreadyJoinWaitList ? (
            <Button
              variant="contained"
              onClick={() => {
                setOpenRequireKyc(false);
              }}
              size={'large'}
              fullWidth
            >
              {t('common:action.ok')}
            </Button>
          ) : (
            <>
              <Button
                variant="outlined"
                onClick={() => {
                  setOpenRequireKyc(false);
                }}
                size={'large'}
                sx={{
                  flex: 1,
                }}
                disabled={isLoadingJoinWaitList}
              >
                {t('common:action.cancel')}
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  joinWaitList();
                }}
                size={'large'}
                sx={{
                  flex: 1,
                }}
                disabled={isLoadingJoinWaitList}
              >
                {t('action.join')}
              </Button>
            </>
          )}
        </Stack>
      </BaseActionDialog>
    </>
  );
};

export default ActionRequestForQuote;
