import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Divider,
  Grid,
  Skeleton,
  Stack,
  SvgIcon,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  RfqDetail,
  RfqStatus,
  useRfqDetail,
  useUpdateQuoteMeta,
  useUpdateQuote,
} from '../../../../services/brokers';
import { ModalLayout, SnackbarContext, useToast } from 'ui/components';
import ActionFetchRfqDetail from '../../components/ActionFetchRfqDetail';
import TypographyWithLoading from '../../components/TypographyWithLoading';
import BaseActionDialog from 'ui/components/modal/BaseActionDialog';
import { thousandSeparated } from 'ui/utils';
import { isNil, isNumber } from 'lodash';
import InformationMark from 'ui/components/InformationMark';
import WarningTriangleIcon from 'ui/icons/warning_triangle.svg?react';
import RfqHeader from '../../orders/components/RfqHeader';
import ActionCancelQuote from './ActionCancelQuote';
import InputPartialFillSize from './InputPartialFillSize';
import InputPrice from './InputPrice';
import DetailLabel from '../../components/DetailLabel';
import ColumnDirection from '../../components/ColumnDirection';
import ColumnCreatedAt from '../../components/ColumnCreatedAt';
import ColumnStatus from './ColumnStatus';
import ColumnSettlementDate from '../../components/ColumnSettlementDate';
import { useIndex } from '../../../../services/bonds';
import priceOutOfMidPriceRangeAlertText from '../../components/priceOutOfMidPriceRangeAlertText';
import {
  Services,
  useTraderServiceIsDisabled,
} from '../../../../config/serviceIsDisabled';
import { useMeasure } from 'react-use';
import dayjs from 'dayjs';
import InputSettlementDate from './InputSettlementDate';
import { formatApiErrorToast } from '../../../../services/apiUtils';

interface FormValues {
  price: string | null;
  size: string | null;
  brokerSpread: string | null;
  settlementDate: dayjs.Dayjs | null;
}

const ActionUpdateQuote: React.FC<{
  children: (props: { onClick: () => void; disabled?: boolean }) => JSX.Element;
  onSuccess?: () => void;
  onOpen?: () => void;
  onClose?: () => void;
  rfq: RfqDetail;
}> = ({ children, onSuccess, onOpen, onClose: onCloseProp, rfq }) => {
  const { t } = useTranslation(['rfq', 'validation']);
  const theme = useTheme();
  const onMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = useState(false);
  const toast = useToast();
  const [headerContainerRef, { height: headerHeight }] = useMeasure();

  const brokerSpreadDisabled = useTraderServiceIsDisabled(
    Services.TRADE_RFQ_BROKER_SPREAD,
  );

  const { data, refetch: refetchRfqDetail } = useRfqDetail(
    String(rfq?.id),
    {
      view: 1,
    },
    {
      enabled: open,
      refetchOnWindowFocus: true,
      keepPreviousData: true,
      cacheTime: 0,
    },
  );
  const { data: bondIndex } = useIndex(
    {
      page: 1,
      size: 1,
      isinList: rfq?.assetIdentifier,
    },
    {
      enabled: open && Boolean(rfq?.assetIdentifier),
    },
  );
  const bondDetail = useMemo(() => bondIndex?.content[0], [bondIndex]);

  const currentBestPrice = useMemo(() => {
    if (data?.direction === 'BUY') {
      return data?.bestQuote || Infinity;
    } else {
      return data?.bestQuote || 0;
    }
  }, [data?.bestQuote, data?.direction]);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        price: yup
          .number()
          .required()
          .label(t('field.price').toLowerCase())
          .moreThan(0, t('validation:custom.greaterThanZero')),
        size: yup
          .number()
          .optional()
          .label(t('field.size').toLowerCase())
          .test(
            'requiredIfPartialFill',
            t('validation:custom.requiredIfPartialFill'),
            (value) => {
              if (!data?.isPartialFill) return true;
              return !isNil(value);
            },
          )
          .moreThan(0, t('validation:custom.greaterThanZero'))
          .test(
            'shouldNotExceed',
            t('validation:custom.shouldNotExceed', {
              size: data?.initSize,
            }),
            (value) => {
              if (!data?.initSize) return true;
              if (!value) return true;
              return value <= data?.initSize;
            },
          ),
        brokerSpread: yup
          .number()
          .optional()
          .nullable()
          .label(t('field.brokerSpread').toLowerCase()),
        settlementDate: yup.date().required(),
      }),
    [t, data?.direction, currentBestPrice, data?.isPartialFill, data?.initSize],
  );
  const defaultValues = useMemo(() => {
    return {
      price: data?.quote?.toString() || null,
      size: data?.quoteSize?.toString() || null,
      brokerSpread: data?.brokerSpread?.toString() || null,
      settlementDate: data?.settlementDate ? dayjs(data?.settlementDate) : null,
    };
  }, [data]);
  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty, dirtyFields },
  } = useForm<FormValues>({
    defaultValues,
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });
  const formValues = useWatch({
    control,
  });
  const isFormValuesValid = useMemo(() => {
    return validationSchema.isValidSync(formValues);
  }, [formValues]);

  const ableToChangeQuote = useMemo(() => {
    return data?.reQuoteChance || data?.status === RfqStatus.Quoted;
  }, [data?.reQuoteChance, data?.status]);

  const sizeHelperText = useMemo(() => {
    if (
      data?.isPartialFill &&
      formValues.size &&
      Number(formValues.size) < data?.size
    ) {
      return t('tooltip.partialFillSizeWillBeLowPriority');
    }
    return '';
  }, [data?.isPartialFill, formValues.size, data?.size]);

  const priceHelperText = useMemo(() => {
    let helperText = '';
    const textColor = 'warning.main';
    // If offered price is out of mid price +- 40% range, show alert
    if (formValues.price && bondDetail?.midPriceClean) {
      const alert = priceOutOfMidPriceRangeAlertText(
        bondDetail,
        Number(formValues.price),
        t,
      );

      helperText = alert ?? '';
    } else if (
      data?.status === RfqStatus.WaitingQuote &&
      data?.bestQuote &&
      formValues.price
    ) {
      switch (true) {
        case data?.direction === 'BUY' &&
          Number(formValues.price) > data?.bestQuote:
          helperText = t('tooltip.theresABetterPrice');
          break;
        case data?.direction === 'SELL' &&
          Number(formValues.price) < data?.bestQuote:
          helperText = t('tooltip.theresABetterPrice');
          break;
      }
    }

    if (!helperText) return '';

    return (
      <Stack direction={'row'} spacing={1} alignItems={'center'}>
        <SvgIcon
          sx={{
            width: 16,
            height: 16,
            color: textColor,
          }}
        >
          <WarningTriangleIcon />
        </SvgIcon>
        <Typography variant="body2" color="warning.main" gutterBottom>
          {helperText}
        </Typography>
      </Stack>
    );
  }, [data, formValues.price, bondDetail?.midPriceClean]);

  const { mutate, isLoading: isLoadingUpdateQuote } = useUpdateQuote({
    onSuccess: () => {
      toast.success(
        t('title.quoteUpdatedSuccess'),
        t('message.quoteUpdatedSuccess'),
        {
          duration: 10,
        },
      );
      onClose();
      onSuccess?.();
    },
    onError: async (error) => {
      const resData = await refetchRfqDetail().then((res) => {
        return res.data;
      });
      if (resData?.status === RfqStatus.OrderPlaced) {
        toast.error(
          t('title.quoteOrderAlreadyPlaced'),
          t('message.quoteOrderAlreadyPlaced'),
        );

        setOpen(false);
        return;
      }

      toast.error(...formatApiErrorToast(t, error?.message));
    },
  });
  const { mutate: updateQuoteMeta, isLoading: isLoadingUpdateBrokerSpread } =
    useUpdateQuoteMeta({
      onSuccess(data, variables, context) {
        reset({
          ...defaultValues,
          ...(variables.brokerSpread && {
            brokerSpread: variables.brokerSpread?.toString(),
          }),
          ...(variables.settlementDate && {
            settlementDate: dayjs(variables.settlementDate),
          }),
        });
        toast.success(t('title.brokerSpreadUpdated'));
      },
    });
  const isLoading = isLoadingUpdateQuote || isLoadingUpdateBrokerSpread;

  const handleClick = () => {
    setOpen(true);
  };

  const onClose = () => {
    reset(defaultValues);
    setOpen(false);
    onMobile
      ? setTimeout(() => {
          onCloseProp?.();
        }, theme.transitions.duration.standard)
      : onCloseProp?.();
  };

  const onSubmit: SubmitHandler<FormValues> = async (formData) => {
    if (!rfq) return;
    if (!formData.price) return;
    console.log('formData', formData);
    await Promise.all([
      (dirtyFields.price || dirtyFields.size) &&
        mutate({
          rfqId: String(rfq.id),
          quoteId: String(rfq.quoteId),
          price: Number(formData.price),
          ...(data?.isPartialFill && { size: Number(formData.size) }),
        }),
      !isNil(data?.quoteId) &&
        ((dirtyFields.brokerSpread &&
          isNumber(formData.brokerSpread) &&
          !brokerSpreadDisabled) ||
          dirtyFields.settlementDate) &&
        updateQuoteMeta({
          rfqId: rfq.id,
          quoteId: data?.quoteId,
          ...(dirtyFields.brokerSpread && {
            brokerSpread: Number(formData.brokerSpread),
          }),
          ...(dirtyFields.settlementDate && {
            settlementDate: dayjs(formData.settlementDate).format('YYYY-MM-DD'),
          }),
        }),
    ]);
  };

  const renderActions = useCallback(
    (data: RfqDetail) => (
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'end'}
        spacing={2}
      >
        {!onMobile && <div />}
        {onMobile && (
          <ActionCancelQuote rfq={data} onCanceled={onClose}>
            {({ onClick, disabled }) =>
              !disabled ? (
                <Button
                  variant="outlined"
                  onClick={onClick}
                  disabled={disabled}
                  size="large"
                  fullWidth
                >
                  {t('action.cancelQuote')}
                </Button>
              ) : null
            }
          </ActionCancelQuote>
        )}
        <Box>
          <Button
            variant={isFormValuesValid ? 'contained' : 'text'}
            onClick={() => {
              handleSubmit(onSubmit)();
            }}
            disabled={
              !isFormValuesValid || !isDirty || !ableToChangeQuote || isLoading
            }
            size="large"
            fullWidth={onMobile}
          >
            {t('action.updateQuote')}
          </Button>
        </Box>
      </Stack>
    ),
    [onMobile, isFormValuesValid, isDirty, ableToChangeQuote, isLoading, t],
  );

  const renderContent = useCallback(
    () => (
      <>
        <Box ref={headerContainerRef}>
          <RfqHeader rfq={rfq} />
        </Box>
        <Divider />
        <Box
          pl={{
            xs: 2,
            lg: 8,
          }}
          pr={{
            xs: 2,
            lg: 4,
          }}
          py={{
            xs: 2,
            lg: 4,
          }}
          maxHeight={{
            xs: `calc(90dvh - ${headerHeight}px - 4px)`,
            md: 'unset',
          }}
          overflow={{
            xs: 'auto',
            md: 'unset',
          }}
        >
          <ActionFetchRfqDetail rfqId={rfq?.id} view={1} enabled={open}>
            {({ data, isLoading: isLoadingData }) => (
              <>
                <Grid
                  container
                  rowSpacing={{
                    xs: 2,
                    md: 4,
                  }}
                  columnSpacing={2}
                >
                  {onMobile && (
                    <Grid item xs={12}>
                      <DetailLabel>{t('field.status')}</DetailLabel>
                      <TypographyWithLoading loading={isLoadingData}>
                        <ColumnStatus rfq={data} />
                      </TypographyWithLoading>
                    </Grid>
                  )}
                  <Grid item xs={6} md={4}>
                    <Stack direction={'row'} spacing={1} mb={'0.35em'}>
                      <DetailLabel>{t('field.size')}</DetailLabel>
                      {!onMobile && sizeHelperText && (
                        <InformationMark
                          title={sizeHelperText}
                          slots={{
                            icon: (
                              <SvgIcon
                                sx={{
                                  width: 16,
                                  height: 16,
                                  color: 'warning.main',
                                }}
                              >
                                <WarningTriangleIcon />
                              </SvgIcon>
                            ),
                          }}
                        />
                      )}
                    </Stack>
                    {data?.isPartialFill && ableToChangeQuote ? (
                      <Controller
                        name="size"
                        control={control}
                        render={({ field, fieldState }) => (
                          <InputPartialFillSize
                            rfq={data}
                            {...field}
                            error={!!fieldState.error}
                            helperText={
                              onMobile
                                ? fieldState.error?.message || sizeHelperText
                                : fieldState.error?.message
                            }
                            disabled={!ableToChangeQuote}
                          />
                        )}
                      />
                    ) : (
                      <TypographyWithLoading loading={isLoadingData}>
                        {thousandSeparated(data?.quoteSize || 0)}
                      </TypographyWithLoading>
                    )}
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <DetailLabel>{t('field.price')}</DetailLabel>
                    <Controller
                      name="price"
                      control={control}
                      render={({ field, fieldState }) => (
                        <Stack spacing={1}>
                          <InputPrice
                            {...field}
                            placeholder={t('tooltip.layQuote')}
                            error={!!fieldState.error}
                            helperText={
                              fieldState.error?.message ?? priceHelperText
                            }
                            disabled={!ableToChangeQuote}
                          />
                          {priceHelperText && (
                            <Stack
                              direction={'row'}
                              spacing={1}
                              alignItems={'center'}
                            >
                              <SvgIcon
                                sx={{
                                  width: 16,
                                  height: 16,
                                  color: 'warning.main',
                                }}
                              >
                                <WarningTriangleIcon />
                              </SvgIcon>
                              <Typography
                                variant="body2"
                                color="warning.main"
                                gutterBottom
                              >
                                {priceHelperText}
                              </Typography>
                            </Stack>
                          )}
                        </Stack>
                      )}
                    />
                  </Grid>
                  {!brokerSpreadDisabled && (
                    <Grid item xs={6} md={4}>
                      <DetailLabel>{t('field.brokerSpread')}</DetailLabel>
                      <Controller
                        name="brokerSpread"
                        control={control}
                        render={({ field, fieldState }) => (
                          <InputPrice
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Grid>
                  )}
                  <Grid item xs={6} md={4}>
                    <DetailLabel>{t('field.settlementDate')}</DetailLabel>
                    <Controller
                      name="settlementDate"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputSettlementDate
                          {...field}
                          slotProps={{
                            textField: {
                              error: !!fieldState.error,
                              helperText: fieldState.error?.message,
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <DetailLabel>{t('field.direction')}</DetailLabel>
                    {isLoadingData ? (
                      <Skeleton variant="text" width={100} />
                    ) : (
                      <ColumnDirection rfq={data} variant="chip" />
                    )}
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <DetailLabel>{t('bond:field.isin')}</DetailLabel>
                    <TypographyWithLoading loading={isLoadingData}>
                      {data?.assetIdentifier || '-'}
                    </TypographyWithLoading>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <DetailLabel>
                      {t('bond:field.tradingRestrictionType')}
                    </DetailLabel>
                    <TypographyWithLoading loading={isLoadingData}>
                      {data?.bond?.tradingRestrictionType || '-'}
                    </TypographyWithLoading>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <DetailLabel>{t('field.quoteRequestedAt')}</DetailLabel>
                    <TypographyWithLoading loading={isLoadingData}>
                      <ColumnCreatedAt rfq={data} />
                    </TypographyWithLoading>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <DetailLabel>{t('field.id')}</DetailLabel>
                    <TypographyWithLoading loading={isLoadingData}>
                      {data?.id || '-'}
                    </TypographyWithLoading>
                  </Grid>
                  {!onMobile && data && (
                    <Grid item xs={12}>
                      {renderActions(data)}
                    </Grid>
                  )}
                </Grid>
                {onMobile && data && (
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      right: 16,
                      pl: 2,
                      pb: 2,
                      bgcolor: (theme) => theme.palette.background.default,
                    }}
                  >
                    {renderActions(data)}
                  </Box>
                )}
              </>
            )}
          </ActionFetchRfqDetail>
          {onMobile && <Box height={80} />}
        </Box>
      </>
    ),
    [
      t,
      onMobile,
      rfq,
      open,
      sizeHelperText,
      control,
      renderActions,
      ableToChangeQuote,
      isLoading,
      headerHeight,
      priceHelperText,
    ],
  );

  useEffect(() => {
    if (open) {
      onOpen?.();
    }
  }, [open]);

  useEffect(() => {
    defaultValues && reset(defaultValues);
  }, [defaultValues]);

  if (onMobile) {
    return (
      <>
        {children({
          onClick: handleClick,
        })}
        <ModalLayout
          open={open}
          onClose={() => {
            if (isLoading) return;
            onClose();
          }}
        >
          {renderContent()}
        </ModalLayout>
      </>
    );
  }

  return (
    <>
      {children({ onClick: handleClick, disabled: !rfq.quote })}
      <BaseActionDialog
        open={open}
        onClose={() => {
          if (isLoading) return;
          onClose();
        }}
        width={{
          xs: '90%',
          md: '80%',
          lg: 976,
        }}
      >
        {renderContent()}
      </BaseActionDialog>
    </>
  );
};

export default ActionUpdateQuote;
