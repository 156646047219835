import {
  inputBaseClasses,
  outlinedInputClasses,
  TextField,
  TextFieldProps,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { RfqDetail } from '../../../../services/brokers';
import { thousandSeparated } from 'ui/utils';
import { NumericFormat } from 'react-number-format';
import { isNil } from 'lodash';
import { inputClasses } from '@mui/base';

const InputPartialFillSize: React.FC<
  Omit<TextFieldProps, 'onChange'> & {
    rfq: RfqDetail | undefined;
    onChange?: (value: number) => void;
  }
> = ({ rfq, defaultValue, type, value, onChange, ...props }) => {
  const theme = useTheme();
  const onMobile = useMediaQuery(theme.breakpoints.down('md'));

  if (!rfq) {
    return null;
  }

  return (
    <NumericFormat
      thousandSeparator
      allowNegative={false}
      customInput={TextField}
      size={onMobile ? 'small' : 'medium'}
      variant="outlined"
      fullWidth
      decimalScale={0}
      InputProps={{
        endAdornment: (
          <Typography
            variant="body2"
            color="text.secondary"
            whiteSpace={'nowrap'}
          >
            {`/ ${thousandSeparated(rfq?.initSize || 0)}`}
          </Typography>
        ),
        ...props.InputProps,
      }}
      value={value as number}
      onValueChange={({ floatValue }, { event }) => {
        if (!event) return;
        if (isNil(floatValue)) return;
        onChange?.(floatValue);
      }}
      sx={{
        [`& .${inputBaseClasses.input}.${outlinedInputClasses.input}.${inputClasses.disabled}`]:
          {
            color: 'text.secondary',
            '-webkit-text-fill-color': (theme) => theme.palette.text.secondary,
          },
      }}
      {...props}
    />
  );
};

export default InputPartialFillSize;
