import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Skeleton,
  Stack,
  SvgIcon,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
  useWatch,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import BaseActionDialog from 'ui/components/modal/BaseActionDialog';
import {
  RfqDetail,
  useIndex as useBrokerIndex,
  useRFQOrder,
} from '../../../../services/brokers';
import { FilterSelect, ModalLayout, useToast } from 'ui/components';
import PlusIcon from 'ui/icons/plus.svg?react';
import ActionFetchRfqDetail from '../../components/ActionFetchRfqDetail';
import TypographyWithLoading from '../../components/TypographyWithLoading';
import ChatIcon from 'ui/icons/chat.svg?react';
import ActionOpenChatOfRfq from '../../../../libs/ui/components/Chat/ActionOpenChatOfRfq';
import RfqHeader from './RfqHeader';
import { serviceIsDisabled } from '../../../../config/serviceIsDisabled';
import { Services } from '../../../../config/type';
import ColumnStatus from './ColumnStatus';
import ColumnSize from '../../components/ColumnSize';
import ColumnPrice from './ColumnPrice';
import ColumnDirection from '../../components/ColumnDirection';
import DetailLabel from '../../components/DetailLabel';
import ColumnSettlementDate from '../../components/ColumnSettlementDate';
import { useMeasure } from 'react-use';
import ColumnQuoteTime from '../../components/ColumnQuoteTime';
import { formatApiErrorToast } from '../../../../services/apiUtils';

const analyticsMetaProps = {
  'data-ga-event': 'action_accept_quote_init',
  'data-ga-category': 'trade',
  'data-testid': 'action-accept-quote',
};
const ActionAcceptQuote: React.FC<{
  children: (
    props: {
      onClick: () => void;
      disabled?: boolean;
    } & typeof analyticsMetaProps,
  ) => JSX.Element;
  onSuccess?: () => void;
  onOpen?: () => void;
  onClose?: () => void;
  rfq: RfqDetail;
}> = ({ children, onSuccess, onOpen, onClose: onCloseProp, rfq }) => {
  const { t } = useTranslation('rfq');
  const theme = useTheme();
  const onMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = useState(false);
  const [brokerConfirmed, setBrokerConfirmed] = useState<boolean>(false);

  const toast = useToast();
  const [headerContainerRef, { height: headerHeight }] = useMeasure();
  const navigate = useNavigate();

  const { data: brokerIndex } = useBrokerIndex({
    page: 1,
    size: 10000,
    status: 3,
  });
  const brokerOptions = useMemo(() => {
    if (!brokerIndex) return [];
    return brokerIndex?.content.map((broker) => ({
      label: `${broker.fi} ${broker.accountCode}`,
      value: broker.id,
    }));
  }, [brokerIndex]);
  const defaultBroker = useMemo(() => {
    if (!brokerIndex) return null;
    return brokerIndex?.content.find((broker) => broker.isDefault)?.id || null;
  }, [brokerIndex, open]);

  const defaultValues = useMemo(() => {
    return {
      quoteId: rfq?.quoteId || rfq?.bestQuoteId,
      price: rfq?.price || rfq?.bestQuote,
      fiAccountId: defaultBroker || null,
      defaultNextTime: Boolean(defaultBroker),
    };
  }, [rfq, defaultBroker]);

  const { control, handleSubmit, reset } = useForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        quoteId: yup.number().required(),
        fiAccountId: yup.number().required(),
        defaultNextTime: yup.boolean(),
      }),
    ),
  });
  const fiAccountId = useWatch({
    control: control,
    name: 'fiAccountId',
  });

  const selectedAccountName = useMemo(() => {
    if (!brokerOptions) return null;
    return brokerOptions?.find((option) => option.value === fiAccountId)?.label;
  }, [brokerOptions, fiAccountId]);

  const defaultNextTime = useWatch({
    control: control,
    name: 'defaultNextTime',
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const handleClick = useCallback(() => {
    setOpen(true);
  }, []);

  const { mutate, isLoading: isOrderingRFQ } = useRFQOrder({
    onSuccess() {
      toast.success(t('title.rfqOrderPlaced'), t('message.rfqOrderPlaced'));
      onClose();
      onSuccess?.();
    },
    onError: async (error, variables) => {
      toast.error(...formatApiErrorToast(t, error?.message));
    },
  });

  const onSubmit: SubmitHandler<{
    quoteId: number;
    price: number;
    fiAccountId: number;
    defaultNextTime: boolean;
  }> = (data) => {
    mutate({
      rfqId: rfq.id,
      ...data,
    });
  };

  const onSubmitError: SubmitErrorHandler<{
    quoteId: number;
    fiAccountId: number;
    defaultNextTime: boolean;
  }> = (data) => {
    console.error(data);
    toast.error(
      t('common:api.error.unknown.title'),
      t('common:api.error.unknown.message'),
    );
  };

  const onClose = () => {
    reset();
    setBrokerConfirmed(false);
    setOpen(false);
    onMobile
      ? setTimeout(() => {
          onCloseProp?.();
        }, theme.transitions.duration.standard)
      : onCloseProp?.();
  };

  const renderBrokerSelect = useCallback(
    (rfq: RfqDetail) => (
      <Stack
        direction={{
          xs: 'column',
          md: 'row',
        }}
        spacing={{
          xs: 1,
          md: 2,
        }}
      >
        <Box
          minWidth={{
            xs: 0,
            md: 320,
          }}
        >
          <Controller
            name="fiAccountId"
            control={control}
            render={({ field, fieldState }) => (
              <FilterSelect
                placeholder={field.value ? '' : t('tooltip.selectAccount')}
                options={brokerOptions}
                {...field}
                defaultValue={defaultBroker}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                fullWidth
                size={onMobile ? 'small' : 'medium'}
              >
                {!serviceIsDisabled(Services.TRADE_BROKER_ADD_BROKER) && (
                  <>
                    {brokerOptions.length > 0 && <Divider />}
                    <MenuItem
                      disableRipple
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate({
                          pathname: '/app/settings/account/broker',
                        });
                      }}
                    >
                      <ListItemIcon>
                        <SvgIcon>
                          <PlusIcon width={24} />
                        </SvgIcon>
                      </ListItemIcon>
                      <ListItemText>{t('action.addBroker')}</ListItemText>
                    </MenuItem>
                  </>
                )}
              </FilterSelect>
            )}
          />
        </Box>
        <Controller
          name="defaultNextTime"
          control={control}
          render={({ field, fieldState }) => (
            <FormControlLabel
              control={
                <Checkbox
                  {...field}
                  defaultChecked={defaultNextTime}
                  disabled={fiAccountId === null}
                />
              }
              sx={{
                whiteSpace: 'nowrap',
              }}
              label={t('options.defaultNextTime')}
            />
          )}
        />
      </Stack>
    ),
    [brokerOptions, defaultBroker, defaultNextTime, fiAccountId, onMobile, t],
  );

  const renderActions = useCallback(
    (rfq: RfqDetail) =>
      !brokerConfirmed ? (
        <Stack
          direction={{
            xs: 'column',
            lg: 'row',
          }}
          spacing={2}
          justifyContent={'space-between'}
        >
          {!onMobile && renderBrokerSelect(rfq)}
          <div />
          <Stack
            direction={'row'}
            justifyContent={{
              xs: 'space-between',
              md: 'flex-end',
            }}
            spacing={2}
          >
            {rfq?.traderName && (
              <ActionOpenChatOfRfq
                username={rfq?.traderName}
                rfq={rfq}
                onSuccess={() => {
                  onClose();
                }}
              >
                {(props) => (
                  <Button
                    variant={'outlined'}
                    color={'primary'}
                    size="large"
                    startIcon={
                      <SvgIcon>
                        <ChatIcon />
                      </SvgIcon>
                    }
                    {...props}
                    fullWidth
                  >
                    {t('action.openChat')}
                  </Button>
                )}
              </ActionOpenChatOfRfq>
            )}
            <Button
              variant={fiAccountId === null ? 'text' : 'contained'}
              onClick={() => {
                setBrokerConfirmed(true);
              }}
              disabled={fiAccountId === null}
              size="large"
              fullWidth
            >
              {t('action.acceptQuote')}
            </Button>
          </Stack>
        </Stack>
      ) : (
        <Stack direction={'row'} justifyContent={'flex-end'} spacing={2}>
          <Button
            variant="outlined"
            onClick={() => {
              setBrokerConfirmed(false);
            }}
            size="large"
            disabled={isOrderingRFQ}
            fullWidth={onMobile}
          >
            {t('action.reselectBroker')}
          </Button>
          <Button
            key={'confirm_quote'}
            variant="contained"
            onClick={handleSubmit((data) => {
              onSubmit({
                ...data,
                fiAccountId: fiAccountId!,
              });
            }, onSubmitError)}
            size="large"
            disabled={isOrderingRFQ}
            fullWidth={onMobile}
          >
            {t('common:action.confirm')}
          </Button>
        </Stack>
      ),
    [
      brokerConfirmed,
      brokerOptions,
      defaultBroker,
      defaultNextTime,
      fiAccountId,
      isOrderingRFQ,
      t,
    ],
  );
  const renderContent = useCallback(
    () => (
      <ActionFetchRfqDetail
        rfqId={rfq?.id}
        view={0}
        enabled={open && !brokerConfirmed}
      >
        {({ data, isLoading: isLoadingData = false }) => (
          <>
            <Box ref={headerContainerRef}>
              <RfqHeader rfq={rfq} subheader={t('tooltip.acceptQuote')} />
            </Box>
            <Divider />
            <Box
              p={{
                xs: 2,
                lg: 4,
              }}
              height={{
                xs: `calc(90dvh - ${headerHeight}px - 4px)`,
                md: 'unset',
              }}
              overflow={{
                xs: 'auto',
                md: 'unset',
              }}
            >
              <Grid
                container
                rowSpacing={{
                  xs: 2,
                  md: 4,
                }}
                columnSpacing={2}
              >
                {onMobile && (
                  <Grid item xs={12}>
                    <DetailLabel>{t('field.status')}</DetailLabel>
                    <TypographyWithLoading loading={isLoadingData}>
                      <ColumnStatus rfq={data} />
                    </TypographyWithLoading>
                  </Grid>
                )}
                <Grid item xs={6} md={4}>
                  <DetailLabel>{t('field.size')}</DetailLabel>
                  <TypographyWithLoading loading={isLoadingData}>
                    <ColumnSize rfq={data} />
                  </TypographyWithLoading>
                </Grid>
                <Grid item xs={6} md={4}>
                  <DetailLabel>{t('field.price')}</DetailLabel>
                  <TypographyWithLoading loading={isLoadingData}>
                    <ColumnPrice rfq={data} />
                  </TypographyWithLoading>
                </Grid>
                <Grid item xs={6} md={4}>
                  <DetailLabel>{t('field.settlementDate')}</DetailLabel>
                  <TypographyWithLoading loading={isLoadingData}>
                    <ColumnSettlementDate rfq={data} />
                  </TypographyWithLoading>
                </Grid>
                <Grid item xs={6} md={4}>
                  <DetailLabel>{t('field.direction')}</DetailLabel>
                  {isLoadingData ? (
                    <Skeleton variant="text" width={100} />
                  ) : (
                    <ColumnDirection rfq={data} variant="chip" />
                  )}
                </Grid>
                <Grid item xs={6} md={4}>
                  <DetailLabel>{t('bond:field.isin')}</DetailLabel>
                  <TypographyWithLoading loading={isLoadingData}>
                    {data?.assetIdentifier || '-'}
                  </TypographyWithLoading>
                </Grid>
                <Grid item xs={6} md={4}>
                  <DetailLabel>
                    {t('bond:field.tradingRestrictionType')}
                  </DetailLabel>
                  <TypographyWithLoading loading={isLoadingData}>
                    {data?.bond?.tradingRestrictionType || '-'}
                  </TypographyWithLoading>
                </Grid>
                <Grid item xs={6} md={4}>
                  <DetailLabel>{t('field.orderAccount')}</DetailLabel>
                  <Typography>{selectedAccountName ?? '-'}</Typography>
                </Grid>
                <Grid item xs={6} md={4}>
                  <DetailLabel>{t('field.quotedAt')}</DetailLabel>
                  <TypographyWithLoading loading={isLoadingData}>
                    <ColumnQuoteTime rfq={data} />
                  </TypographyWithLoading>
                </Grid>
                <Grid item xs={6} md={4}>
                  <DetailLabel>{t('field.id')}</DetailLabel>
                  <TypographyWithLoading loading={isLoadingData}>
                    {data?.id ?? '-'}
                  </TypographyWithLoading>
                </Grid>
                {!onMobile && data && (
                  <Grid item xs={12}>
                    {renderActions(data)}
                  </Grid>
                )}
                {onMobile && data && (
                  <Grid item xs={12}>
                    {renderBrokerSelect(data)}
                  </Grid>
                )}
              </Grid>
              {onMobile && data && (
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 16,
                    pl: 2,
                    pb: 2,
                    bgcolor: (theme) => theme.palette.background.default,
                  }}
                >
                  {renderActions(data)}
                </Box>
              )}
              {onMobile && <Box height={80} />}
            </Box>
          </>
        )}
      </ActionFetchRfqDetail>
    ),
    [
      rfq,
      open,
      brokerConfirmed,
      onMobile,
      t,
      selectedAccountName,
      renderActions,
      headerHeight,
    ],
  );

  useEffect(() => {
    if (open) {
      onOpen?.();
    }
  }, [open]);

  if (!rfq.price && !rfq.bestQuote) return null;

  if (onMobile) {
    return (
      <>
        {children({ onClick: handleClick, ...analyticsMetaProps })}
        <ModalLayout
          open={open}
          onClose={() => {
            if (isOrderingRFQ) return;
            onClose();
          }}
        >
          {renderContent()}
        </ModalLayout>
      </>
    );
  }

  return (
    <>
      {children({ onClick: handleClick, ...analyticsMetaProps })}
      <BaseActionDialog
        open={open}
        onClose={() => {
          if (isOrderingRFQ) return;
          onClose();
        }}
        width={{
          xs: '90%',
          md: '80%',
          lg: 976,
        }}
      >
        {renderContent()}
      </BaseActionDialog>
    </>
  );
};

export default ActionAcceptQuote;
