import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  CardHeader,
  Chip,
  chipClasses,
  Divider,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { RfqStatus } from '../../../../services/brokers';
import DirectionChip from '../../components/DirectionChip';
import ActionFetchRfqDetail from '../../components/ActionFetchRfqDetail';
import TypographyWithLoading from '../../components/TypographyWithLoading';
import { dateFormat, thousandSeparated } from 'ui/utils';
import { RfqDetail } from '../../../../services/brokers';
import BaseActionDialog from 'ui/components/modal/BaseActionDialog';
import StatusColumn from './StatusColumn';
import ActionCancelRfq from './ActionCancelRfq';
import { ModalLayout } from 'ui/components';
import { NavLink } from 'react-router-dom';
import OpenNewIcon from 'ui/icons/open-new.svg?react';
import RfqHeader from './RfqHeader';
import DetailLabel from '../../components/DetailLabel';
import ColumnStatus from './ColumnStatus';
import ColumnSize from '../../components/ColumnSize';
import ColumnPrice from './ColumnPrice';
import ColumnDirection from '../../components/ColumnDirection';
import ColumnRequestTime from '../../components/ColumnRequestTime';
import ColumnSettlementDate from '../../components/ColumnSettlementDate';
import ActionAcceptQuote from './ActionAcceptQuote';

const ActionCheckWaitingQuote: React.FC<{
  children: (props: { onClick: () => void; disabled?: boolean }) => JSX.Element;
  onOpen?: () => void;
  onClose?: () => void;
  rfq: RfqDetail;
}> = ({ children, onOpen, onClose: onCloseProp, rfq }) => {
  const { t } = useTranslation('rfq');
  const theme = useTheme();
  const onMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
    onMobile
      ? setTimeout(() => {
          onCloseProp?.();
        }, theme.transitions.duration.standard)
      : onCloseProp?.();
  };

  const renderContent = useCallback(
    () => (
      <>
        <RfqHeader
          rfq={rfq}
          subheader={
            [RfqStatus.OrderPlaced, RfqStatus.OrderExecuted].includes(
              rfq?.status,
            )
              ? t('tooltip.acceptQuote')
              : ''
          }
        />
        <Divider />
        <Box
          pl={{
            xs: 2,
            lg: 8,
          }}
          pr={{
            xs: 2,
            lg: 4,
          }}
          py={{
            xs: 2,
            lg: 4,
          }}
        >
          <ActionFetchRfqDetail rfqId={rfq?.id} view={0} enabled={open}>
            {({ data, isLoading: isLoadingData }) => (
              <Grid
                container
                rowSpacing={{
                  xs: 2,
                  md: 4,
                }}
                columnSpacing={2}
              >
                {onMobile && data && (
                  <Grid item xs={12} md={4}>
                    <DetailLabel>{t('field.status')}</DetailLabel>
                    <TypographyWithLoading loading={isLoadingData}>
                      <ColumnStatus rfq={data} />
                    </TypographyWithLoading>
                  </Grid>
                )}
                <Grid item xs={6} md={4}>
                  <DetailLabel>{t('field.size')}</DetailLabel>
                  <TypographyWithLoading loading={isLoadingData}>
                    <ColumnSize rfq={data} />
                  </TypographyWithLoading>
                </Grid>
                <Grid item xs={6} md={4}>
                  <DetailLabel>{t('field.price')}</DetailLabel>
                  <TypographyWithLoading loading={isLoadingData}>
                    <ColumnPrice rfq={data} />
                  </TypographyWithLoading>
                </Grid>
                <Grid item xs={6} md={4}>
                  <DetailLabel>{t('field.settlementDate')}</DetailLabel>
                  <TypographyWithLoading loading={isLoadingData}>
                    <ColumnSettlementDate rfq={data} />
                  </TypographyWithLoading>
                </Grid>
                <Grid item xs={6} md={4}>
                  <DetailLabel>{t('field.direction')}</DetailLabel>
                  {isLoadingData ? (
                    <Skeleton variant="text" width={100} />
                  ) : (
                    <ColumnDirection rfq={data} variant="chip" />
                  )}
                </Grid>
                <Grid item xs={6} md={4}>
                  <DetailLabel>{t('bond:field.isin')}</DetailLabel>
                  <TypographyWithLoading loading={isLoadingData}>
                    {data?.assetIdentifier || '-'}
                  </TypographyWithLoading>
                </Grid>
                <Grid item xs={6} md={4}>
                  <DetailLabel>
                    {t('bond:field.tradingRestrictionType')}
                  </DetailLabel>
                  <TypographyWithLoading loading={isLoadingData}>
                    {data?.bond?.tradingRestrictionType || '-'}
                  </TypographyWithLoading>
                </Grid>
                <Grid item xs={6} md={4}>
                  <DetailLabel>{t('field.id')}</DetailLabel>
                  <TypographyWithLoading loading={isLoadingData}>
                    {data?.id ?? '-'}
                  </TypographyWithLoading>
                </Grid>
                <Grid item xs={6} md={4}>
                  <DetailLabel>{t('field.orderedAt')}</DetailLabel>
                  <TypographyWithLoading loading={isLoadingData}>
                    <ColumnRequestTime rfq={data} />
                  </TypographyWithLoading>
                </Grid>
              </Grid>
            )}
          </ActionFetchRfqDetail>
        </Box>
        {onMobile && (
          <Stack
            direction={'row'}
            spacing={2}
            justifyContent={'space-between'}
            sx={{
              position: 'absolute',
              bottom: 16,
              left: 16,
              right: 16,
            }}
          >
            <ActionCancelRfq rfq={rfq} onCanceled={onClose}>
              {({ onClick }) => (
                <Button
                  variant="outlined"
                  fullWidth
                  size="large"
                  onClick={onClick}
                >
                  {t('action.cancelOrder')}
                </Button>
              )}
            </ActionCancelRfq>
            <ActionAcceptQuote rfq={rfq}>
              {({ onClick }) => (
                <Button variant="contained" onClick={onClick}>
                  {t('action.acceptQuote')}
                </Button>
              )}
            </ActionAcceptQuote>
          </Stack>
        )}
      </>
    ),
    [rfq, t, open, onMobile],
  );

  useEffect(() => {
    if (open) {
      onOpen?.();
    }
  }, [open]);

  if (onMobile) {
    return (
      <>
        {children({ onClick: handleClick })}
        <ModalLayout open={open} onClose={onClose}>
          {renderContent()}
        </ModalLayout>
      </>
    );
  }

  return (
    <>
      {children({ onClick: handleClick })}

      <BaseActionDialog
        open={open}
        onClose={onClose}
        width={{
          xs: '90%',
          md: '80%',
          lg: 976,
        }}
      >
        {renderContent()}
      </BaseActionDialog>
    </>
  );
};

export default ActionCheckWaitingQuote;
