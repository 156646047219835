import { FC, useMemo } from 'react';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Announcement, AnnouncementType } from 'ui/components/chat/type';
import { Bond, useIndex } from '../../../../services/bonds';
import { get } from 'lodash';
import ActionRequestForQuote from '../actions/ActionRequestForQuote';
import { formatBondName } from 'ui/utils';
import { RfqStatus, useRfqDetail } from '../../../../services/brokers';
import { usePermittedV2 } from '../../../hooks/usePermissions';
import ActionReadRfq from '../../../../pages/rfq/components/ActionReadRfq';
import ActionOrderDetail from '../../../../pages/rfq/orders/components/ActionOrderDetail';
import ActionCheckWaitingQuote from '../../../../pages/rfq/orders/components/ActionCheckWaitingQuote';
import ActionAcceptQuote from '../../../../pages/rfq/orders/components/ActionAcceptQuote';
import ActionResponseOrderUpdate from '../../../../pages/rfq/orders/components/ActionResponseOrderUpdate';
import ActionLayQuote from '../../../../pages/rfq/blotter/components/ActionLayQuote';
import ActionUpdateQuote from '../../../../pages/rfq/blotter/components/ActionUpdateQuote';
import ActionResponseOrder from '../../../../pages/rfq/blotter/components/ActionResponseOrder';
import ActionCheckOrderUpdate from '../../../../pages/rfq/blotter/components/ActionCheckOrderUpdate';
import { RfqQuoteStatus } from 'services/broker';

const ChatroomAnnouncementAction: FC<{
  announcement?: Announcement;
}> = ({ announcement }) => {
  const { t } = useTranslation(['bond', 'rfq']);

  // chat room bond detail
  const { data: bondData } = useIndex(
    {
      page: 1,
      size: 1,
      isinList: announcement?.parameters?.isin as string,
    },
    {
      enabled:
        announcement?.announcementType === AnnouncementType.BOND &&
        !!announcement?.parameters?.isin,
    },
  );
  const bond = useMemo(
    () => get(bondData, 'content.0') as Bond | undefined,
    [bondData],
  );

  // AnnouncementType.RFQ
  const [isClient] = usePermittedV2('page.order');
  const { data: rfqDetail } = useRfqDetail(
    String(announcement?.parameters?.rfqId),
    { view: isClient ? 0 : 1 },
    {
      enabled:
        announcement?.announcementType === AnnouncementType.RFQ &&
        !!announcement?.parameters?.rfqId,
    },
  );

  switch (true) {
    case announcement?.announcementType === AnnouncementType.BOND:
      return bond ? (
        <Box ml={1}>
          <ActionRequestForQuote
            asset={{
              id: bond?.id,
              assetName: formatBondName(bond as Bond),
              companyName: bond?.longObligorName,
            }}
          >
            {({ onClick, ...props }) => (
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  onClick(e);
                }}
                {...props}
              >
                {t('bond:action.tradeNow')}
              </Button>
            )}
          </ActionRequestForQuote>
        </Box>
      ) : null;
    case announcement?.announcementType === AnnouncementType.RFQ && !!isClient:
      return rfqDetail ? (
        <Box ml={1}>
          {[
            RfqStatus.Expired,
            RfqStatus.OrderPlaced,
            RfqStatus.OrderCancelledByClient,
            RfqStatus.OrderExecuted,
            RfqStatus.OrderCancelledByClient,
            RfqStatus.OrderCancelledByBlotter,
            RfqStatus.OrderCancelledByClient,
          ].includes(rfqDetail?.status) && (
            <ActionReadRfq rfq={rfqDetail}>
              {({ read }) => (
                <ActionOrderDetail
                  rfq={rfqDetail}
                  onOpen={() => {
                    read();
                  }}
                >
                  {({ onClick, ...props }) => (
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        onClick(e);
                      }}
                      {...props}
                    >
                      {t('rfq:action.orderDetail')}
                    </Button>
                  )}
                </ActionOrderDetail>
              )}
            </ActionReadRfq>
          )}
          {rfqDetail?.status === RfqStatus.WaitingQuote && (
            <ActionReadRfq rfq={rfqDetail}>
              {({ read }) => (
                <ActionCheckWaitingQuote
                  rfq={rfqDetail}
                  onOpen={() => {
                    read();
                  }}
                >
                  {({ onClick }) => (
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        onClick(e);
                      }}
                    >
                      {t('rfq:action.orderDetail')}
                    </Button>
                  )}
                </ActionCheckWaitingQuote>
              )}
            </ActionReadRfq>
          )}
          {rfqDetail?.status === RfqStatus.Quoted && (
            <ActionReadRfq rfq={rfqDetail}>
              {({ read }) => (
                <ActionAcceptQuote
                  rfq={rfqDetail}
                  onOpen={() => {
                    read();
                  }}
                >
                  {({ onClick }) => (
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        onClick(e);
                      }}
                    >
                      {t('rfq:action.acceptQuote')}
                    </Button>
                  )}
                </ActionAcceptQuote>
              )}
            </ActionReadRfq>
          )}
          {rfqDetail?.status === RfqStatus.OrderUpdated && (
            <ActionReadRfq rfq={rfqDetail}>
              {({ read }) => (
                <ActionResponseOrderUpdate
                  rfq={rfqDetail}
                  onOpen={() => {
                    read();
                  }}
                >
                  {({ onClick }) => (
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        onClick(e);
                      }}
                    >
                      {t('rfq:action.checkOrderUpdate')}
                    </Button>
                  )}
                </ActionResponseOrderUpdate>
              )}
            </ActionReadRfq>
          )}
        </Box>
      ) : null;
    case announcement?.announcementType === AnnouncementType.RFQ && !isClient:
      return rfqDetail ? (
        <Box ml={1}>
          {rfqDetail?.status === RfqStatus.WaitingQuote &&
            rfqDetail.quoteStatus !== RfqQuoteStatus.Pending && (
              <ActionReadRfq rfq={rfqDetail}>
                {({ read }) => (
                  <ActionLayQuote
                    rfq={rfqDetail}
                    onOpen={() => {
                      read();
                    }}
                  >
                    {({ onClick }) => (
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          onClick(e);
                        }}
                      >
                        {t('rfq:action.layQuote')}
                      </Button>
                    )}
                  </ActionLayQuote>
                )}
              </ActionReadRfq>
            )}
          {rfqDetail?.status === RfqStatus.WaitingQuote &&
            rfqDetail.quoteStatus === RfqQuoteStatus.Pending && (
              <ActionReadRfq rfq={rfqDetail}>
                {({ read }) => (
                  <ActionUpdateQuote
                    rfq={rfqDetail}
                    onOpen={() => {
                      read();
                    }}
                  >
                    {({ onClick }) => (
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          onClick(e);
                        }}
                      >
                        {t('rfq:action.updateQuote')}
                      </Button>
                    )}
                  </ActionUpdateQuote>
                )}
              </ActionReadRfq>
            )}
          {[
            RfqStatus.Expired,
            RfqStatus.Quoted,
            RfqStatus.OrderExecuted,
            RfqStatus.ClientRejected,
            RfqStatus.OrderCancelledByBlotter,
            RfqStatus.OrderCancelledByClient,
          ].includes(rfqDetail?.status) && (
            <ActionReadRfq rfq={rfqDetail}>
              {({ read }) => (
                <ActionOrderDetail rfq={rfqDetail}>
                  {({ onClick }) => (
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        onClick(e);
                      }}
                    >
                      {t('rfq:action.orderDetail')}
                    </Button>
                  )}
                </ActionOrderDetail>
              )}
            </ActionReadRfq>
          )}

          {rfqDetail?.status === RfqStatus.OrderPlaced && (
            <ActionReadRfq rfq={rfqDetail}>
              {({ read }) => (
                <ActionResponseOrder
                  rfq={rfqDetail}
                  onOpen={() => {
                    read();
                  }}
                >
                  {({ onClick }) => (
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        onClick(e);
                      }}
                    >
                      {t('rfq:action.orderPlaced')}
                    </Button>
                  )}
                </ActionResponseOrder>
              )}
            </ActionReadRfq>
          )}

          {rfqDetail?.status === RfqStatus.OrderUpdated && (
            <ActionCheckOrderUpdate rfq={rfqDetail}>
              {({ onClick }) => (
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    onClick(e);
                  }}
                >
                  {t('rfq:action.checkOrderUpdate')}
                </Button>
              )}
            </ActionCheckOrderUpdate>
          )}
        </Box>
      ) : null;
    default:
      return null;
  }
};

export default ChatroomAnnouncementAction;
