import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  buttonBaseClasses,
  Divider,
  Grid,
  Skeleton,
  Stack,
  SvgIcon,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import BaseActionDialog from 'ui/components/modal/BaseActionDialog';
import ActionCancelOrder from './ActionCancelOrder';
import {
  OrderFeedbackStatus,
  RfqDetail,
  useRfqDetail,
  useSubmitOrderFeedback,
  useUpdateQuoteMeta,
} from '../../../../services/brokers';
import { ModalLayout, useToast } from 'ui/components';
import dayjs from 'dayjs';
import ActionFetchRfqDetail from '../../components/ActionFetchRfqDetail';
import TypographyWithLoading from '../../components/TypographyWithLoading';
import ChatIcon from 'ui/icons/chat.svg?react';
import { thousandSeparated } from 'ui/utils';
import ActionOpenChatOfRfq from '../../../../libs/ui/components/Chat/ActionOpenChatOfRfq';
import PenOnPanelIcon from 'ui/icons/pen-on-panel.svg?react';
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import RfqHeader from '../../orders/components/RfqHeader';
import InputPartialFillSize from './InputPartialFillSize';
import InputPrice from './InputPrice';
import DetailLabel from '../../components/DetailLabel';
import ColumnStatus from './ColumnStatus';
import ColumnSize from '../../components/ColumnSize';
import ColumnPrice from './ColumnPrice';
import ColumnDirection from '../../components/ColumnDirection';
import ColumnClientAccount from '../../components/ColumnClientAccount';
import ColumnRequestTime from '../../components/ColumnRequestTime';
import ColumnSettlementDate from '../../components/ColumnSettlementDate';
import { useIndex } from '../../../../services/bonds';
import priceOutOfMidPriceRangeAlertText from '../../components/priceOutOfMidPriceRangeAlertText';
import { isNil, isNumber } from 'lodash';
import WarningTriangleIcon from 'ui/icons/warning_triangle.svg?react';
import {
  Services,
  useTraderServiceIsDisabled,
} from '../../../../config/serviceIsDisabled';
import { useMeasure } from 'react-use';
import InputSettlementDate from './InputSettlementDate';
import { formatApiErrorToast } from '../../../../services/apiUtils';

type UpdateOrderFormValues = {
  size: string;
  price: string;
  brokerSpread?: string;
  settlementDate: dayjs.Dayjs | null;
};

const ActionResponseOrder: React.FC<{
  children: (props: { onClick: () => void; disabled?: boolean }) => JSX.Element;
  onSuccess?: () => void;
  onCanceled?: () => void;
  onOpen?: () => void;
  onClose?: () => void;
  rfq: RfqDetail;
}> = ({
  children,
  onSuccess,
  onCanceled,
  onOpen,
  onClose: onCloseProp,
  rfq,
}) => {
  const { t } = useTranslation('rfq');
  const theme = useTheme();
  const onMobile = useMediaQuery(theme.breakpoints.down('md'));
  const onTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const toast = useToast();
  const [headerContainerRef, { height: headerHeight }] = useMeasure();

  const brokerSpreadDisabled = useTraderServiceIsDisabled(
    Services.TRADE_RFQ_BROKER_SPREAD,
  );

  const { data } = useRfqDetail(
    String(rfq?.id),
    {
      view: 1,
    },
    {
      enabled: open,
      refetchOnWindowFocus: true,
      keepPreviousData: true,
      cacheTime: 0,
    },
  );
  const { data: bondIndex } = useIndex(
    {
      page: 1,
      size: 1,
      isinList: rfq?.assetIdentifier,
    },
    {
      enabled: open && Boolean(rfq?.assetIdentifier),
    },
  );
  const bondDetail = useMemo(() => bondIndex?.content[0], [bondIndex]);

  const defaultValues = useMemo(() => {
    return {
      size: data?.size.toString(),
      price: data?.price?.toString(),
      brokerSpread: (data?.brokerSpread ?? 0).toString(),
      settlementDate: data?.settlementDate ? dayjs(data?.settlementDate) : null,
    };
  }, [data]);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        size: yup
          .number()
          .optional()
          .label(t('field.size').toLowerCase())
          .test(
            'requiredIfPartialFill',
            t('validation:custom.requiredIfPartialFill'),
            (value) => {
              if (!data?.isPartialFill) return true;
              return !isNil(value);
            },
          )
          .test(
            'greaterThanZero',
            t('validation:custom.greaterThanZero'),
            (value) => isNil(value) || value > 0,
          )
          .test(
            'shouldNotExceed',
            t('validation:custom.shouldNotExceed', {
              size: data?.initSize,
            }),
            (value) => {
              if (!data?.initSize) return true;
              if (!value) return true;
              return value <= data?.initSize;
            },
          ),
        price: yup
          .number()
          .required()
          .label(t('field.price').toLowerCase())
          .test(
            'greaterThanZero',
            t('validation:custom.greaterThanZero'),
            (value) => !!value && value > 0,
          ),
        brokerSpread: yup
          .number()
          .optional()
          .nullable()
          .label(t('field.brokerSpread').toLowerCase()),
        settlementDate: yup.date().required(),
      }),
    [t, data],
  );

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty, dirtyFields },
  } = useForm<UpdateOrderFormValues>({
    defaultValues,
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
  });
  const price = useWatch({
    control,
    name: 'price',
  });

  const {
    mutate: submitOrderFeedback,
    isLoading: isLoadingSubmitOrderFeedback,
  } = useSubmitOrderFeedback({
    onSuccess: (data, variables) => {
      switch (variables?.status) {
        case OrderFeedbackStatus.Updated:
          toast.success(t('message.submitOrderUpdateSuccess'));
          break;
        case OrderFeedbackStatus.Executed:
        case OrderFeedbackStatus.CanceledByBroker:
          toast.success(t('message.submitOrderFeedbackSuccess'));
      }
      onClose();
      onSuccess?.();
    },
    onError: (err) => {
      const error = err?.response?.data?.error;
      toast.error(...formatApiErrorToast(t, error?.message));
    },
  });
  const { mutate: updateQuoteMeta, isLoading: isLoadingUpdateBrokerSpread } =
    useUpdateQuoteMeta({
      onSuccess(data, variables, context) {
        reset({
          ...defaultValues,
          ...(variables.brokerSpread && {
            brokerSpread: variables.brokerSpread?.toString(),
          }),
          ...(variables.settlementDate && {
            settlementDate: dayjs(variables.settlementDate),
          }),
        });
        toast.success(t('title.brokerSpreadUpdated'));
      },
    });

  const isLoading = isLoadingSubmitOrderFeedback || isLoadingUpdateBrokerSpread;

  const onSubmitOrderExecuted = async () => {
    if (!data?.size || !data?.price) {
      toast.error(
        t('common:api.error.unknown.title'),
        t('common:api.error.unknown.message'),
      );
      return;
    }
    submitOrderFeedback({
      rfqId: String(rfq.id),
      status: OrderFeedbackStatus.Executed,
      executedAt: dayjs().format('YYYY-MM-DD hh:mm:ss'),
      size: data?.size.toString(),
      price: data?.price.toString(),
    });
  };

  const onSubmitOrderUpdated: SubmitHandler<UpdateOrderFormValues> = async (
    formData,
  ) => {
    await Promise.all([
      (dirtyFields.size || dirtyFields.price) &&
        submitOrderFeedback({
          rfqId: String(rfq.id),
          status: OrderFeedbackStatus.Updated,
          executedAt: dayjs().format('YYYY-MM-DD hh:mm:ss'),
          size: formData.size,
          price: formData.price,
        }),
      !isNil(data?.quoteId) &&
        ((dirtyFields.brokerSpread &&
          isNumber(formData.brokerSpread) &&
          !brokerSpreadDisabled) ||
          dirtyFields.settlementDate) &&
        updateQuoteMeta({
          rfqId: rfq.id,
          quoteId: data?.quoteId,
          ...(dirtyFields.brokerSpread && {
            brokerSpread: Number(formData.brokerSpread),
          }),
          ...(dirtyFields.settlementDate && {
            settlementDate: dayjs(formData.settlementDate).format('YYYY-MM-DD'),
          }),
        }),
    ]);
  };

  const onOrderCanceled = () => {
    if (isLoading) return;
    onClose();
    onCanceled?.();
  };

  const handleClick = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setOpenEdit(false);
    reset(defaultValues);
    onMobile
      ? setTimeout(() => {
          onCloseProp?.();
        }, theme.transitions.duration.standard)
      : onCloseProp?.();
  };

  const priceHelperText = useMemo(() => {
    // If offered price is out of mid price +- 40% range, show alert
    if (price && isNumber(Number(price)) && bondDetail?.midPriceClean) {
      const alert = priceOutOfMidPriceRangeAlertText(
        bondDetail,
        Number(price),
        t,
      );

      if (alert) {
        return alert;
      }
    }

    return '';
  }, [price, bondDetail, t]);

  const renderActions = useCallback(
    (data: RfqDetail) => (
      <Stack
        direction={{
          xs: 'column',
          lg: 'row',
        }}
        justifyContent={{
          xs: 'flex-start',
          lg: 'space-between',
        }}
      >
        <Box>
          {data?.username && !onTablet && (
            <ActionOpenChatOfRfq
              username={data?.username}
              rfq={data}
              onSuccess={() => {
                onClose();
              }}
            >
              {(props) => (
                <Button
                  variant={'outlined'}
                  color={'primary'}
                  size="large"
                  startIcon={
                    <SvgIcon>
                      <ChatIcon />
                    </SvgIcon>
                  }
                  {...props}
                >
                  {t('action.openChat')}
                </Button>
              )}
            </ActionOpenChatOfRfq>
          )}
        </Box>
        <Box>
          <Stack direction={'row'} spacing={2}>
            {!onMobile && (
              <Button
                variant={'outlined'}
                color={'primary'}
                size="large"
                startIcon={
                  <SvgIcon>
                    <PenOnPanelIcon />
                  </SvgIcon>
                }
                onClick={() => {
                  setOpenEdit(true);
                }}
                fullWidth
              >
                {t('action.updateOrder')}
              </Button>
            )}
            <ActionCancelOrder rfq={rfq} onCanceled={onOrderCanceled}>
              {({ onClick }) => (
                <Button
                  variant="outlined"
                  onClick={onClick}
                  size="large"
                  disabled={isLoading}
                  fullWidth
                >
                  {t('action.reportOrderCancelled')}
                </Button>
              )}
            </ActionCancelOrder>
            <Button
              variant="contained"
              onClick={() => {
                onSubmitOrderExecuted();
              }}
              size="large"
              disabled={isLoading}
              fullWidth
            >
              {t('action.finishOrder')}
            </Button>
          </Stack>
        </Box>
      </Stack>
    ),
    [
      onTablet,
      onMobile,
      t,
      rfq,
      onOrderCanceled,
      isLoading,
      onClose,
      onSubmitOrderExecuted,
    ],
  );
  const renderContent = useCallback(
    () => (
      <>
        <Box ref={headerContainerRef}>
          <RfqHeader rfq={rfq} subheader={t('tooltip.responseOrder')} />
        </Box>
        <Divider />
        <Box
          p={{
            xs: 2,
            lg: 8,
          }}
          pr={{
            xs: 2,
            lg: 4,
          }}
          py={{
            xs: 2,
            lg: 4,
          }}
          maxHeight={{
            xs: `calc(90dvh - ${headerHeight}px - 4px)`,
            md: 'unset',
          }}
          overflow={{
            xs: 'auto',
            md: 'unset',
          }}
        >
          <ActionFetchRfqDetail rfqId={rfq?.id} view={1} enabled={open}>
            {({ data, isLoading: isLoadingData }) => (
              <>
                <Grid
                  container
                  rowSpacing={{
                    xs: 2,
                    md: 4,
                  }}
                  columnSpacing={2}
                >
                  {onMobile && (
                    <Grid item xs={12} md={4}>
                      <DetailLabel>{t('field.status')}</DetailLabel>
                      <TypographyWithLoading loading={isLoadingData}>
                        <ColumnStatus rfq={data} />
                      </TypographyWithLoading>
                    </Grid>
                  )}
                  <Grid item xs={6} md={4}>
                    <DetailLabel>{t('field.size')}</DetailLabel>
                    <TypographyWithLoading loading={isLoadingData}>
                      <ColumnSize rfq={data} />
                    </TypographyWithLoading>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <DetailLabel>{t('field.price')}</DetailLabel>
                    <TypographyWithLoading loading={isLoadingData}>
                      <ColumnPrice rfq={data} />
                    </TypographyWithLoading>
                  </Grid>
                  {!brokerSpreadDisabled && (
                    <Grid item xs={6} md={4}>
                      <DetailLabel>{t('field.brokerSpread')}</DetailLabel>
                      <TypographyWithLoading loading={isLoadingData}>
                        {thousandSeparated(data?.brokerSpread ?? 0)}
                      </TypographyWithLoading>
                    </Grid>
                  )}
                  <Grid item xs={6} md={4}>
                    <DetailLabel>{t('field.settlementDate')}</DetailLabel>

                    <TypographyWithLoading loading={isLoadingData}>
                      <ColumnSettlementDate rfq={data} />
                    </TypographyWithLoading>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <DetailLabel>{t('field.direction')}</DetailLabel>
                    {isLoadingData ? (
                      <Skeleton variant="text" width={100} />
                    ) : (
                      <ColumnDirection rfq={data} variant="chip" />
                    )}
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <DetailLabel>{t('bond:field.isin')}</DetailLabel>
                    <TypographyWithLoading loading={isLoadingData}>
                      {data?.assetIdentifier || '-'}
                    </TypographyWithLoading>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <DetailLabel>
                      {t('bond:field.tradingRestrictionType')}
                    </DetailLabel>
                    <TypographyWithLoading loading={isLoadingData}>
                      {data?.bond?.tradingRestrictionType || '-'}
                    </TypographyWithLoading>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <DetailLabel>{t('field.clientAccount')}</DetailLabel>
                    <TypographyWithLoading loading={isLoadingData}>
                      <ColumnClientAccount rfq={data} />
                    </TypographyWithLoading>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <DetailLabel>{t('field.orderedAt')}</DetailLabel>
                    <TypographyWithLoading loading={isLoadingData}>
                      <ColumnRequestTime rfq={data} />
                    </TypographyWithLoading>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <DetailLabel>{t('field.id')}</DetailLabel>
                    <TypographyWithLoading loading={isLoadingData}>
                      {data?.id ?? '-'}
                    </TypographyWithLoading>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <DetailLabel>{t('field.clientContact')}</DetailLabel>
                    <TypographyWithLoading loading={isLoadingData}>
                      {data?.rmEmail ?? '-'}
                    </TypographyWithLoading>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <DetailLabel>{t('field.clientName')}</DetailLabel>
                    <TypographyWithLoading loading={isLoadingData}>
                      {data?.fullName ?? '-'}
                    </TypographyWithLoading>
                  </Grid>
                  {!onMobile && data && (
                    <Grid item xs={12}>
                      {renderActions(data)}
                    </Grid>
                  )}
                  {onMobile && data && (
                    <Grid item xs={12}>
                      <DetailLabel>
                        {t('message.updateOrderOrOpenChat')}
                      </DetailLabel>
                      <Stack direction={'row'} spacing={2}>
                        <ActionOpenChatOfRfq
                          username={data?.username}
                          rfq={data}
                          onSuccess={() => {
                            onClose();
                          }}
                        >
                          {(props) => (
                            <Button
                              variant={'outlined'}
                              color={'primary'}
                              size="large"
                              startIcon={
                                <SvgIcon>
                                  <ChatIcon />
                                </SvgIcon>
                              }
                              {...props}
                              sx={{
                                [`&.${buttonBaseClasses.root}`]: {
                                  minWidth: 0,
                                },
                                [`& .MuiButton-startIcon`]: {
                                  mr: 0,
                                  ml: 0,
                                },
                              }}
                            ></Button>
                          )}
                        </ActionOpenChatOfRfq>
                        <Button
                          variant={'outlined'}
                          color={'primary'}
                          size="large"
                          disableRipple
                          startIcon={
                            <SvgIcon>
                              <PenOnPanelIcon />
                            </SvgIcon>
                          }
                          onClick={() => {
                            setOpenEdit(true);
                          }}
                          sx={{
                            [`&.${buttonBaseClasses.root}`]: {
                              minWidth: 0,
                            },
                            [`& .MuiButton-startIcon`]: {
                              mr: 0,
                              ml: 0,
                            },
                          }}
                        ></Button>
                      </Stack>
                    </Grid>
                  )}
                </Grid>
                {onMobile && data && (
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      right: 0,
                      px: 2,
                      pb: 2,
                      bgcolor: (theme) => theme.palette.background.default,
                    }}
                  >
                    {renderActions(data)}
                  </Box>
                )}
              </>
            )}
          </ActionFetchRfqDetail>
          {onMobile && <Box height={80} />}
        </Box>
      </>
    ),
    [rfq, t, open, onMobile, renderActions, headerHeight],
  );
  const renderEditActions = useCallback(
    (data: RfqDetail) => (
      <Stack
        direction={{
          xs: 'column',
          lg: 'row',
        }}
        justifyContent={'flex-end'}
        spacing={2}
      >
        <Button
          variant={'outlined'}
          color={'primary'}
          size="large"
          disabled={isLoading}
          onClick={() => {
            setOpenEdit(false);
            reset(defaultValues);
          }}
        >
          {t('common:action.cancel')}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            handleSubmit(onSubmitOrderUpdated)();
          }}
          size="large"
          disabled={isLoading || !isDirty}
        >
          {t('action.updateOrderConfirm')}
        </Button>
      </Stack>
    ),
    [isLoading, t, isDirty],
  );
  const renderEditContent = useCallback(
    () => (
      <>
        <Box ref={headerContainerRef}>
          <RfqHeader rfq={rfq} subheader={t('tooltip.updateOrder')} />
        </Box>
        <Divider />
        <Box
          pl={{
            xs: 2,
            lg: 8,
          }}
          pr={{
            xs: 2,
            lg: 4,
          }}
          py={{
            xs: 2,
            lg: 4,
          }}
          maxHeight={{
            xs: `calc(90dvh - ${headerHeight}px - 4px)`,
            md: 'unset',
          }}
          overflow={{
            xs: 'auto',
            md: 'unset',
          }}
        >
          <ActionFetchRfqDetail rfqId={rfq?.id} view={1} enabled={open}>
            {({ data, isLoading: isLoadingData }) => (
              <>
                <Grid
                  container
                  rowSpacing={{
                    xs: 2,
                    md: 4,
                  }}
                  columnSpacing={2}
                >
                  {onMobile && (
                    <Grid item xs={12} md={4}>
                      <DetailLabel>{t('field.status')}</DetailLabel>
                      <TypographyWithLoading loading={isLoadingData}>
                        <ColumnStatus rfq={data} />
                      </TypographyWithLoading>
                    </Grid>
                  )}
                  <Grid item xs={6} md={4}>
                    <DetailLabel>{t('field.size')}</DetailLabel>
                    <Controller
                      name="size"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputPartialFillSize
                          rfq={data}
                          {...field}
                          placeholder={t('tooltip.layQuote')}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          disabled={isLoading}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <DetailLabel>{t('field.price')}</DetailLabel>
                    <Controller
                      name="price"
                      control={control}
                      render={({ field, fieldState }) => (
                        <Stack spacing={1} width={'100%'}>
                          <InputPrice
                            {...field}
                            placeholder={t('tooltip.layQuote')}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            disabled={isLoading}
                          />
                          {priceHelperText && (
                            <Stack
                              direction={'row'}
                              spacing={1}
                              alignItems={'center'}
                            >
                              <SvgIcon
                                sx={{
                                  width: 16,
                                  height: 16,
                                  color: 'warning.main',
                                }}
                              >
                                <WarningTriangleIcon />
                              </SvgIcon>
                              <Typography
                                variant="body2"
                                color="warning.main"
                                gutterBottom
                              >
                                {priceHelperText}
                              </Typography>
                            </Stack>
                          )}
                        </Stack>
                      )}
                    />
                  </Grid>
                  {!brokerSpreadDisabled && (
                    <Grid item xs={6} md={4}>
                      <DetailLabel>{t('field.brokerSpread')}</DetailLabel>
                      <Controller
                        name="brokerSpread"
                        control={control}
                        render={({ field, fieldState }) => (
                          <InputPrice
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Grid>
                  )}
                  <Grid item xs={6} md={4}>
                    <DetailLabel>{t('field.settlementDate')}</DetailLabel>
                    <Controller
                      name="settlementDate"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputSettlementDate
                          {...field}
                          slotProps={{
                            textField: {
                              error: !!fieldState.error,
                              helperText: fieldState.error?.message,
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <DetailLabel>{t('field.direction')}</DetailLabel>
                    {isLoadingData ? (
                      <Skeleton variant="text" width={100} />
                    ) : (
                      <ColumnDirection rfq={data} variant="chip" />
                    )}
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <DetailLabel>{t('bond:field.isin')}</DetailLabel>
                    <TypographyWithLoading loading={isLoadingData}>
                      {data?.assetIdentifier || '-'}
                    </TypographyWithLoading>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <DetailLabel>
                      {t('bond:field.tradingRestrictionType')}
                    </DetailLabel>
                    <TypographyWithLoading loading={isLoadingData}>
                      {data?.bond?.tradingRestrictionType || '-'}
                    </TypographyWithLoading>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <DetailLabel>{t('field.clientAccount')}</DetailLabel>
                    <TypographyWithLoading loading={isLoadingData}>
                      <ColumnClientAccount rfq={data} />
                    </TypographyWithLoading>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <DetailLabel>{t('field.orderedAt')}</DetailLabel>
                    <TypographyWithLoading loading={isLoadingData}>
                      <ColumnRequestTime rfq={data} />
                    </TypographyWithLoading>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <DetailLabel>{t('field.id')}</DetailLabel>
                    <TypographyWithLoading loading={isLoadingData}>
                      {data?.id ?? '-'}
                    </TypographyWithLoading>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <DetailLabel>{t('field.clientContact')}</DetailLabel>
                    <TypographyWithLoading loading={isLoadingData}>
                      {data?.rmEmail ?? '-'}
                    </TypographyWithLoading>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <DetailLabel>{t('field.clientName')}</DetailLabel>
                    <TypographyWithLoading loading={isLoadingData}>
                      {data?.fullName ?? '-'}
                    </TypographyWithLoading>
                  </Grid>
                  {!onMobile && data && (
                    <Grid item xs={12}>
                      {renderEditActions(data)}
                    </Grid>
                  )}
                </Grid>
                {onMobile && data && (
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      right: 16,
                      pl: 2,
                      pb: 2,
                      bgcolor: (theme) => theme.palette.background.default,
                    }}
                  >
                    {renderEditActions(data)}
                  </Box>
                )}
              </>
            )}
          </ActionFetchRfqDetail>
          {onMobile && <Box height={160} />}
        </Box>
      </>
    ),
    [
      rfq,
      t,
      open,
      onMobile,
      renderEditActions,
      isLoading,
      priceHelperText,
      headerHeight,
    ],
  );

  useEffect(() => {
    if (open) {
      onOpen?.();
    }
  }, [open]);

  useEffect(() => {
    defaultValues && reset(defaultValues);
  }, [defaultValues]);

  if (onMobile) {
    return (
      <>
        {children({
          onClick: handleClick,
        })}
        <ModalLayout
          open={open}
          onClose={() => {
            if (isLoading) return;
            onClose();
          }}
        >
          {renderContent()}
        </ModalLayout>
        <ModalLayout
          open={openEdit}
          onClose={() => {
            if (isLoading) return;
            setOpenEdit(false);
            setOpen(true);
          }}
        >
          {renderEditContent()}
        </ModalLayout>
      </>
    );
  }

  return (
    <>
      {children({ onClick: handleClick })}
      <BaseActionDialog
        open={open}
        onClose={() => {
          if (isLoading) return;
          onClose();
        }}
        width={{
          xs: '90%',
          md: '80%',
          lg: 976,
        }}
      >
        {renderContent()}
      </BaseActionDialog>
      <BaseActionDialog
        open={openEdit}
        onClose={() => {
          if (isLoading) return;
          setOpenEdit(false);
          setOpen(true);
        }}
        width={{
          xs: '90%',
          md: '80%',
          lg: 976,
        }}
      >
        {renderEditContent()}
      </BaseActionDialog>
    </>
  );
};

export default ActionResponseOrder;
