import { Chip, chipClasses, Stack, Typography } from '@mui/material';
import { RfqDetail, RfqStatus } from '../../../services/brokers';
import { thousandSeparated } from 'ui/utils';
import { useTranslation } from 'react-i18next';
import React from 'react';

const ColumnSize: React.FC<{
  rfq: RfqDetail | undefined;
  hidePartialFill?: boolean;
}> = ({ rfq, hidePartialFill = false }) => {
  const { t } = useTranslation('rfq');
  if (!rfq) {
    return null;
  }

  let displaySize = thousandSeparated(rfq?.size || 0) as React.ReactNode;

  if (
    ![
      RfqStatus.Expired,
      RfqStatus.ClientRejected,
      RfqStatus.OrderExecuted,
      RfqStatus.OrderCancelledByBlotter,
      RfqStatus.OrderCancelledByClient,
    ].includes(rfq.status)
  ) {
    switch (true) {
      case rfq?.size && rfq?.orderSize && rfq?.size !== rfq?.orderSize:
        displaySize = (
          <Stack direction="row" spacing={1}>
            <Chip
              label={t('common:option.old')}
              size="small"
              sx={{
                textTransform: 'uppercase',
                [`& .${chipClasses.root}`]: {
                  backgroundColor: '#232525',
                },
                [`& .${chipClasses.label}`]: {
                  color: 'text.secondary',
                },
              }}
            />
            <Typography
              color={'textSecondary'}
              component={'span'}
              sx={{
                textDecoration: 'line-through',
              }}
            >
              {thousandSeparated(rfq?.size)}
            </Typography>
            <Chip
              label={t('common:option.new')}
              color="primary"
              size="small"
              sx={{
                textTransform: 'uppercase',
              }}
            />
            <Typography component={'span'}>
              {thousandSeparated(rfq?.orderSize)}
            </Typography>
          </Stack>
        );
        break;
      case rfq?.size && rfq?.quoteSize && rfq?.size !== rfq?.quoteSize:
        displaySize = (
          <Stack direction="row" spacing={1}>
            <Chip
              label={t('common:option.old')}
              size="small"
              sx={{
                textTransform: 'uppercase',
                [`& .${chipClasses.root}`]: {
                  backgroundColor: '#232525',
                },
                [`& .${chipClasses.label}`]: {
                  color: 'text.secondary',
                },
              }}
            />
            <Typography
              color={'textSecondary'}
              component={'span'}
              sx={{
                textDecoration: 'line-through',
              }}
            >
              {thousandSeparated(rfq?.size)}
            </Typography>
            <Chip
              label={t('common:option.new')}
              color="primary"
              size="small"
              sx={{
                textTransform: 'uppercase',
              }}
            />
            <Typography component={'span'}>
              {thousandSeparated(rfq?.quoteSize)}
            </Typography>
          </Stack>
        );
        break;
    }
  }

  return (
    <>
      {displaySize}
      {rfq?.isPartialFill && !hidePartialFill && (
        <Typography
          component={'span'}
          color={'text.secondary'}
          fontSize={'inherit'}
        >
          {` / ${thousandSeparated(rfq?.initSize || 0)}`}
        </Typography>
      )}
    </>
  );
};

export default ColumnSize;
