// features:
// - Dynamic column
// - Hide columns
// - Column sorting
// - Column resizing
// - Column reordering
// - Column pinning (left or right, multiple columns)
// - Draggable
// - Infinite scrolling
// - Loading indicator
// - Checkbox selection
// - Virtualization

import {
  TableContainer,
  Paper,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  SxProps,
  Theme,
  Stack,
  Typography,
  Checkbox,
  LinearProgress,
  TableHeadProps,
  TableBodyProps,
  TableContainerProps,
  Box,
  Skeleton,
  IconButton,
  SvgIcon,
  MenuItem,
  ListItemText,
  TableRowProps,
  Popover,
  List,
  ListItem,
  Button,
  Switch,
} from '@mui/material';
import {
  cloneElement,
  FC,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import SortIconButton from './SortIconButton';
import type { DataColumn } from './types';
import { every, isNumber, isString } from 'lodash';
import { Draggable } from './Draggable';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useHoverDirty, useLocalStorage, useMeasure } from 'react-use';
import ThreeDotsVertical from 'ui/icons/three-dots-vertical.svg?react';
import Menu from '../Menu';
import { useTranslation } from 'react-i18next';

type Id = number | string;

export type DataTableActionHandle = {
  toggleColumnVisibility: (col: string) => void;
  setDisplayColumns: (col: string[]) => void;
};
interface Props {
  columns: DataColumn<unknown>[];
  rows: {
    id: Id;
    [key: string]: unknown;
  }[];
  loading?: boolean;
  onRowClick?: (row: unknown) => void;
  sortBy?: string;
  order?: 'asc' | 'desc';
  onSort?: (key: string) => void;
  minWidth?: number;
  emptyMessage?: React.ReactNode;
  elevation?: number;
  sx?: SxProps<Theme> | undefined;
  checked?: Id[];
  witchCheckbox?: boolean;
  onToggleCheck?: (id: Id) => void;
  onToggleCheckAll?: (ids: Id[], value: boolean) => void;
  slotProps?: {
    tableContainer?: TableContainerProps;
    tableHead?: TableHeadProps;
    tableBody?: TableBodyProps;
    tableRow?: TableRowProps;
  };
  onLoadingNext?: () => void;
  hasNextPage?: boolean;
  rowComponent?: (props: { row: unknown } & TableRowProps) => React.ReactNode;
  onColumnOrderChanged?: (columns: string[]) => void;
  columnOrderStorageKey?: string;
  disableColumnManagement?: boolean;
}

const DataTable = forwardRef<DataTableActionHandle, Props>(
  (
    {
      columns,
      rows,
      loading = false,
      onRowClick,
      sortBy,
      order,
      onSort,
      minWidth = 1400,
      emptyMessage = 'No Data',
      elevation = 0,
      sx,
      witchCheckbox = false,
      checked = [],
      onToggleCheck,
      onToggleCheckAll,
      slotProps,
      onLoadingNext,
      hasNextPage,
      rowComponent,
      onColumnOrderChanged,
      columnOrderStorageKey,
      disableColumnManagement,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const containerRef = useRef<HTMLDivElement>(null);
    const [headerRef, { height: headerHeight }] =
      useMeasure<HTMLTableRowElement>();

    const renderColumnSort = (key: string) => (
      <SortIconButton
        order={sortBy === key ? order : undefined}
        onClick={() => {
          onSort && onSort(key);
        }}
      />
    );

    const allChecked = useMemo(() => {
      if (rows.length === 0) {
        return false;
      }
      return rows.every((row) => checked.includes(row.id));
    }, [checked, rows]);

    const indeterminate = useMemo(() => {
      if (allChecked) {
        return false;
      }

      return rows.some((row) => checked.includes(row.id));
    }, [allChecked, checked, rows]);

    const [loadingNextRef] = useInfiniteScroll({
      loading: loading,
      hasNextPage: Boolean(hasNextPage),
      onLoadMore: () => {
        onLoadingNext?.();
      },
    });

    const defaultColumns = useMemo(
      () => columns.map((column) => column.field),
      [columns],
    );
    const [displayColumns, setDisplayColumns] = useState<string[]>(
      columnOrderStorageKey
        ? localStorage.getItem(columnOrderStorageKey)?.split(',') ??
            defaultColumns
        : defaultColumns,
    );

    const columnManagementOptions = useMemo(() => {
      return columns.map((column) => ({
        // only use first p tag of column.label
        label: column.optionLabel ?? column.label,
        value: column.field,
        enabled: displayColumns.includes(column.field),
        disabled: column.disableHidable,
      }));
    }, [columns, displayColumns]);

    const [
      columnVisibilityTogglePopoverAnchorEl,
      setColumnVisibilityTogglePopoverAnchorEl,
    ] = useState<HTMLElement | null>(null);
    const openColumnVisibilityTogglePopover = Boolean(
      columnVisibilityTogglePopoverAnchorEl,
    );
    const columnVisibilityTogglePopoverAnchorElRef =
      useRef<HTMLHRElement | null>(null);

    const actionHideColumn = useCallback(
      (field: string) => ({
        label: t('common:action.hideTableColumn'),
        onClick: () => {
          setDisplayColumns((prev) => prev.filter((item) => item !== field));
        },
      }),
      [setDisplayColumns, t],
    );
    const actionMoveColumnLeft = useCallback(
      (field: string) => ({
        label: t('common:action.moveTableColumnLeft'),
        onClick: () => {
          const index = displayColumns.indexOf(field);
          if (index > 0) {
            setDisplayColumns((prev) => [
              ...prev.slice(0, index - 1),
              field,
              prev[index - 1],
              ...prev.slice(index + 1),
            ]);
          }
        },
      }),
      [setDisplayColumns, displayColumns, t],
    );
    const actionMoveColumnLeftEnd = useCallback(
      (field: string) => ({
        label: t('common:action.moveTableColumnLeftEnd'),
        onClick: () => {
          const visibleColumns = displayColumns
            .map((key) => columns.find((column) => column.field === key))
            .filter(Boolean);
          const columnsPinnedLeftCount = visibleColumns.filter(
            (column) => column?.pinned === 'left',
          ).length;
          const index = displayColumns.indexOf(field);
          if (index >= columnsPinnedLeftCount) {
            setDisplayColumns((prev) => {
              const copy = [...prev];

              const newColumns = copy.filter((item) => item !== field);

              newColumns.splice(columnsPinnedLeftCount, 0, field);

              return newColumns;
            });
          }
        },
      }),
      [setDisplayColumns, displayColumns, columns, t],
    );
    const actionMoveColumnRight = useCallback(
      (field: string) => ({
        label: t('common:action.moveTableColumnRight'),
        onClick: () => {
          const index = displayColumns.indexOf(field);
          if (index < displayColumns.length - 1) {
            setDisplayColumns((prev) => [
              ...prev.slice(0, index),
              prev[index + 1],
              field,
              ...prev.slice(index + 2),
            ]);
          }
        },
      }),
      [displayColumns, setDisplayColumns, t],
    );
    const actionMoveColumnRightEnd = useCallback(
      (field: string) => ({
        label: t('common:action.moveTableColumnRightEnd'),
        onClick: () => {
          const index = displayColumns.indexOf(field);
          const visibleColumns = displayColumns
            .map((key) => columns.find((column) => column.field === key))
            .filter(Boolean);
          const columnsPinnedRightCount = visibleColumns.filter(
            (column) => column?.pinned === 'right',
          ).length;
          if (index < displayColumns.length - 1) {
            setDisplayColumns((prev) => {
              const copy = [...prev];

              const newColumns = copy.filter((item) => item !== field);

              newColumns.splice(
                prev.length - columnsPinnedRightCount - 1,
                0,
                field,
              );

              return newColumns;
            });
          }
        },
      }),
      [setDisplayColumns, displayColumns, columns, t],
    );
    const actionManageColumns = useMemo(
      () => ({
        label: t('common:action.manageColumns'),
        onClick: () => {
          setColumnVisibilityTogglePopoverAnchorEl(
            columnVisibilityTogglePopoverAnchorElRef.current,
          );
        },
      }),
      [t],
    );

    const stickyLeftColumnRefs = useRef<(HTMLElement | null)[]>([]);
    const stickyLeftColumns = useMemo(() => {
      return columns
        .filter((column) => column.pinned === 'left')
        .filter((column) => displayColumns.includes(column.field))
        .map((column, index) => ({
          ...column,
          columnActions: [
            ...(column?.columnActions || []),
            ...(!disableColumnManagement && !column?.disableHidable
              ? [actionHideColumn(column!.field)]
              : []),
            ...(!disableColumnManagement ? [actionManageColumns] : []),
          ].filter(Boolean),
        }));
    }, [
      actionHideColumn,
      actionManageColumns,
      columns,
      disableColumnManagement,
      displayColumns,
    ]);

    useEffect(() => {
      stickyLeftColumnRefs.current = stickyLeftColumnRefs.current.slice(
        0,
        stickyLeftColumns.length,
      );
    }, [stickyLeftColumns]);

    const stickyRightColumnRefs = useRef<(HTMLElement | null)[]>([]);
    const stickyRightColumns = useMemo(() => {
      return columns
        .filter((column) => column.pinned === 'right')
        .filter((column) => displayColumns.includes(column.field))
        .map((column, index) => ({
          ...column,
          columnActions: [
            ...(column?.columnActions || []),
            ...(!disableColumnManagement && !column?.disableHidable
              ? [actionHideColumn(column!.field)]
              : []),
            ...(!disableColumnManagement ? [actionManageColumns] : []),
          ].filter(Boolean),
        }));
    }, [
      actionHideColumn,
      actionManageColumns,
      columns,
      displayColumns,
      disableColumnManagement,
    ]);

    useEffect(() => {
      stickyRightColumnRefs.current = stickyRightColumnRefs.current.slice(
        0,
        stickyRightColumns.length,
      );
    }, [stickyRightColumns]);

    const nonStickyColumns = useMemo(() => {
      const orderedColumns = columns
        .filter((column) => !column.pinned)
        .map((column) => ({
          ...column,
          order: displayColumns.findIndex((col) => col === column.field),
        }))
        .filter((column) => column.order !== -1)
        .sort((a, b) => a.order - b.order);

      return orderedColumns.map((column, index) => ({
        ...column,
        columnActions: [
          ...(column?.columnActions || []),
          ...(!disableColumnManagement && !column?.disableHidable
            ? [actionHideColumn(column!.field)]
            : []),
          ...(!disableColumnManagement && !column?.pinned && index > 0
            ? [
                actionMoveColumnLeft(column!.field),
                actionMoveColumnLeftEnd(column!.field),
              ]
            : []),
          ...(!disableColumnManagement &&
          !column?.pinned &&
          index < orderedColumns.length - 1
            ? [
                actionMoveColumnRight(column!.field),
                actionMoveColumnRightEnd(column!.field),
              ]
            : []),
          ...(!disableColumnManagement ? [actionManageColumns] : []),
        ].filter(Boolean),
      }));
    }, [
      actionHideColumn,
      actionManageColumns,
      actionMoveColumnLeft,
      actionMoveColumnLeftEnd,
      actionMoveColumnRight,
      actionMoveColumnRightEnd,
      columns,
      displayColumns,
      disableColumnManagement,
    ]);

    useEffect(
      () => {
        onColumnOrderChanged?.([...displayColumns]);
        columnOrderStorageKey &&
          localStorage.setItem(columnOrderStorageKey, displayColumns.join(','));
      } /* eslint-disable-next-line react-hooks/exhaustive-deps */,
      [displayColumns],
    );

    useImperativeHandle(
      ref,
      () => {
        return {
          toggleColumnVisibility: (col: string) => {
            setDisplayColumns((prev) =>
              prev.includes(col)
                ? prev.filter((item) => item !== col)
                : [...prev, col],
            );
          },
          setDisplayColumns: (col: string[]) => {
            setDisplayColumns(col);
          },
        };
      },
      [],
    );

    return (
      <div ref={columnVisibilityTogglePopoverAnchorElRef} className="relative">
        <Draggable>
          <TableContainer
            ref={containerRef}
            component={Paper}
            elevation={elevation}
            sx={{
              borderRadius: 0,
            }}
            data-testid="table-container"
            {...slotProps?.tableContainer}
          >
            <Table
              stickyHeader
              sx={{
                minWidth,
                borderCollapse: 'separate',
                ...sx,
              }}
            >
              <TableHead {...slotProps?.tableHead}>
                <TableRow ref={headerRef}>
                  {stickyLeftColumns.map(
                    (
                      {
                        label,
                        sortable,
                        field,
                        valueGetter,
                        ...tableCellProps
                      },
                      index,
                    ) => (
                      <ColumnStickyHeader
                        key={field}
                        ref={(el) => {
                          stickyLeftColumnRefs.current[index] = el;
                        }}
                        column={{
                          label,
                          sortable,
                          field,
                          valueGetter,
                          ...tableCellProps,
                          sx: {
                            ...tableCellProps.sx,
                            verticalAlign: 'top',
                            position: 'sticky',
                            left:
                              index === 0
                                ? 0
                                : stickyLeftColumnRefs.current[index - 1]
                                    ?.offsetWidth,
                            zIndex: 20,
                          },
                        }}
                      >
                        <Stack
                          direction={'row'}
                          spacing={1}
                          alignItems={'flex-start'}
                          justifyContent={'space-between'}
                        >
                          <Stack
                            direction={'row'}
                            alignItems={'flex-start'}
                            spacing={1}
                            flex={1}
                          >
                            {index === 0 && witchCheckbox && (
                              <Checkbox
                                checked={allChecked}
                                indeterminate={indeterminate}
                                disableRipple
                                focusRipple={false}
                                sx={{
                                  py: 0.5,
                                }}
                                onChange={() => {
                                  onToggleCheckAll &&
                                    onToggleCheckAll(
                                      rows.map((row) => row.id),
                                      !allChecked,
                                    );
                                }}
                              />
                            )}
                            <Box position={'relative'} flex={1}>
                              {isString(label) ? (
                                <Typography
                                  fontSize={'inherit'}
                                  fontWeight={'bold'}
                                  whiteSpace={'nowrap'}
                                >
                                  {label}
                                </Typography>
                              ) : (
                                label
                              )}
                            </Box>
                          </Stack>
                          {sortable && renderColumnSort(field)}
                        </Stack>
                      </ColumnStickyHeader>
                    ),
                  )}
                  {nonStickyColumns.map((column, index) => (
                    <ColumnHeader key={column.field} column={column}>
                      <Stack
                        direction={'row'}
                        spacing={1}
                        alignItems={'flex-start'}
                        justifyContent={'space-between'}
                      >
                        <Stack
                          direction={'row'}
                          alignItems={'flex-start'}
                          spacing={1}
                          flex={1}
                        >
                          {stickyLeftColumns.length + index === 0 &&
                            witchCheckbox && (
                              <Checkbox
                                checked={allChecked}
                                indeterminate={indeterminate}
                                disableRipple
                                focusRipple={false}
                                sx={{
                                  py: 0.5,
                                }}
                                onChange={() => {
                                  onToggleCheckAll &&
                                    onToggleCheckAll(
                                      rows.map((row) => row.id),
                                      !allChecked,
                                    );
                                }}
                              />
                            )}
                          <Box position={'relative'} flex={1}>
                            {isString(column.label) ? (
                              <Typography
                                fontSize={'inherit'}
                                fontWeight={'bold'}
                                whiteSpace={'nowrap'}
                              >
                                {column.label}
                              </Typography>
                            ) : (
                              column.label
                            )}
                          </Box>
                        </Stack>
                        {column.sortable && renderColumnSort(column.field)}
                      </Stack>
                    </ColumnHeader>
                  ))}
                  {stickyRightColumns.map(
                    (
                      {
                        label,
                        sortable,
                        field,
                        valueGetter,
                        ...tableCellProps
                      },
                      index,
                    ) => (
                      <ColumnStickyHeader
                        key={field}
                        ref={(el) => {
                          stickyRightColumnRefs.current[index] = el;
                        }}
                        column={{
                          label,
                          sortable,
                          field,
                          valueGetter,
                          ...tableCellProps,
                          sx: {
                            ...tableCellProps.sx,
                            verticalAlign: 'top',
                            position: 'sticky',
                            right:
                              index === stickyRightColumnRefs.current.length - 1
                                ? 0
                                : stickyRightColumnRefs.current[index + 1]
                                    ?.offsetWidth,
                            zIndex: 20,
                          },
                        }}
                      >
                        <Stack
                          direction={'row'}
                          spacing={1}
                          alignItems={'flex-start'}
                          justifyContent={'space-between'}
                        >
                          <Stack
                            direction={'row'}
                            alignItems={'flex-start'}
                            spacing={1}
                            flex={1}
                          >
                            {stickyLeftColumns.length +
                              nonStickyColumns.length +
                              index ===
                              0 &&
                              witchCheckbox && (
                                <Checkbox
                                  checked={allChecked}
                                  indeterminate={indeterminate}
                                  disableRipple
                                  focusRipple={false}
                                  sx={{
                                    py: 0.5,
                                  }}
                                  onChange={() => {
                                    onToggleCheckAll &&
                                      onToggleCheckAll(
                                        rows.map((row) => row.id),
                                        !allChecked,
                                      );
                                  }}
                                />
                              )}
                            <Box position={'relative'} flex={1}>
                              {isString(label) ? (
                                <Typography
                                  fontSize={'inherit'}
                                  fontWeight={'bold'}
                                  whiteSpace={'nowrap'}
                                >
                                  {label}
                                </Typography>
                              ) : (
                                label
                              )}
                            </Box>
                          </Stack>
                          {sortable && renderColumnSort(field)}
                        </Stack>
                      </ColumnStickyHeader>
                    ),
                  )}
                </TableRow>
              </TableHead>
              <TableBody
                {...slotProps?.tableBody}
                sx={{
                  position: 'relative',
                  ...(slotProps?.tableBody?.sx ?? {}),
                }}
              >
                {rows.map((row, rowNumber) =>
                  rowComponent ? (
                    rowComponent({
                      row,
                      key: row.id,
                      onClick: (e) => {
                        if (onRowClick) {
                          onRowClick(row);
                        }
                      },
                      'data-testid': `table-row-${rowNumber}`,
                      children: (
                        <>
                          {stickyLeftColumns.map(
                            (
                              {
                                label,
                                sortable,
                                field,
                                valueGetter,
                                columnActions,
                                ...tableCellProps
                              },
                              index,
                            ) => (
                              <TableCell
                                component="th"
                                scope="row"
                                {...tableCellProps}
                                key={field}
                                data-testid={`table-cell-${index}`}
                                sx={{
                                  ...tableCellProps.sx,
                                  position: 'sticky',
                                  left:
                                    index === 0
                                      ? 0
                                      : stickyLeftColumnRefs.current[index - 1]
                                          ?.offsetWidth,
                                  zIndex: 10,
                                }}
                              >
                                <Stack direction={'row'} alignItems={'center'}>
                                  {index === 0 && witchCheckbox && (
                                    <Box
                                      sx={{
                                        mr: valueGetter ? 1 : 0,
                                      }}
                                    >
                                      <Checkbox
                                        checked={checked?.includes(row.id)}
                                        disableRipple
                                        focusRipple={false}
                                        onChange={() => {
                                          onToggleCheck &&
                                            onToggleCheck(row.id);
                                        }}
                                      />
                                    </Box>
                                  )}
                                  {valueGetter
                                    ? valueGetter(row, rowNumber) ?? '-'
                                    : row[field] ?? '-'}
                                </Stack>
                              </TableCell>
                            ),
                          )}
                          {nonStickyColumns.map(
                            (
                              {
                                label,
                                sortable,
                                field,
                                valueGetter,
                                columnActions,
                                ...tableCellProps
                              },
                              index,
                            ) => (
                              <TableCell
                                key={field}
                                data-testid={`table-cell-${
                                  stickyLeftColumns.length + index
                                }`}
                                {...tableCellProps}
                              >
                                {valueGetter
                                  ? valueGetter(row, rowNumber) ?? '-'
                                  : row[field] ?? '-'}
                              </TableCell>
                            ),
                          )}
                          {stickyRightColumns.map(
                            (
                              {
                                label,
                                sortable,
                                field,
                                valueGetter,
                                columnActions,
                                ...tableCellProps
                              },
                              index,
                            ) => (
                              <TableCell
                                component="th"
                                scope="row"
                                {...tableCellProps}
                                key={field}
                                data-testid={`table-cell-${
                                  stickyLeftColumns.length +
                                  nonStickyColumns.length +
                                  index
                                }`}
                                sx={{
                                  ...tableCellProps.sx,
                                  position: 'sticky',
                                  right:
                                    index ===
                                    stickyRightColumnRefs.current.length - 1
                                      ? 0
                                      : stickyLeftColumnRefs.current[index + 1]
                                          ?.offsetWidth,
                                  zIndex: 10,
                                }}
                              >
                                <Stack direction={'row'} alignItems={'center'}>
                                  {valueGetter
                                    ? valueGetter(row, rowNumber) ?? '-'
                                    : row[field] ?? '-'}
                                </Stack>
                              </TableCell>
                            ),
                          )}
                        </>
                      ),
                    })
                  ) : (
                    <TableRow
                      key={row.id}
                      onClick={(e) => {
                        if (onRowClick) {
                          onRowClick(row);
                        }
                      }}
                      data-testid={`table-row-${rowNumber}`}
                      sx={{
                        cursor: onRowClick ? 'pointer' : 'default',
                      }}
                    >
                      {stickyLeftColumns.map(
                        (
                          {
                            label,
                            sortable,
                            field,
                            valueGetter,
                            columnActions,
                            ...tableCellProps
                          },
                          index,
                        ) => (
                          <TableCell
                            component="th"
                            scope="row"
                            {...tableCellProps}
                            key={field}
                            data-testid={`table-cell-${index}`}
                            sx={{
                              ...tableCellProps.sx,
                              position: 'sticky',
                              left:
                                index === 0
                                  ? 0
                                  : stickyLeftColumnRefs.current[index - 1]
                                      ?.offsetWidth,
                              zIndex: 10,
                            }}
                          >
                            <Stack direction={'row'} alignItems={'center'}>
                              {index === 0 && witchCheckbox && (
                                <Box
                                  sx={{
                                    mr: valueGetter ? 1 : 0,
                                  }}
                                >
                                  <Checkbox
                                    checked={checked?.includes(row.id)}
                                    disableRipple
                                    focusRipple={false}
                                    onChange={() => {
                                      onToggleCheck && onToggleCheck(row.id);
                                    }}
                                  />
                                </Box>
                              )}
                              {valueGetter
                                ? valueGetter(row, rowNumber) ?? '-'
                                : row[field] ?? '-'}
                            </Stack>
                          </TableCell>
                        ),
                      )}
                      {nonStickyColumns.map(
                        (
                          {
                            label,
                            sortable,
                            field,
                            valueGetter,
                            columnActions,
                            ...tableCellProps
                          },
                          index,
                        ) => (
                          <TableCell
                            key={field}
                            data-testid={`table-cell-${
                              stickyLeftColumns.length + index
                            }`}
                            {...tableCellProps}
                          >
                            {valueGetter
                              ? valueGetter(row, rowNumber) ?? '-'
                              : row[field] ?? '-'}
                          </TableCell>
                        ),
                      )}
                      {stickyRightColumns.map(
                        (
                          {
                            label,
                            sortable,
                            field,
                            valueGetter,
                            columnActions,
                            ...tableCellProps
                          },
                          index,
                        ) => (
                          <TableCell
                            component="th"
                            scope="row"
                            {...tableCellProps}
                            key={field}
                            data-testid={`table-cell-${
                              stickyLeftColumns.length +
                              nonStickyColumns.length +
                              index
                            }`}
                            sx={{
                              ...tableCellProps.sx,
                              position: 'sticky',
                              right:
                                index ===
                                stickyRightColumnRefs.current.length - 1
                                  ? 0
                                  : stickyLeftColumnRefs.current[index + 1]
                                      ?.offsetWidth,
                              zIndex: 10,
                            }}
                          >
                            <Stack direction={'row'} alignItems={'center'}>
                              {valueGetter
                                ? valueGetter(row, rowNumber) ?? '-'
                                : row[field] ?? '-'}
                            </Stack>
                          </TableCell>
                        ),
                      )}
                    </TableRow>
                  ),
                )}
                {rows.length === 0 && !loading && (
                  <TableRow>
                    <TableCell
                      colSpan={
                        nonStickyColumns.length +
                        stickyLeftColumns.length +
                        stickyRightColumns.length
                      }
                      align="center"
                      sx={{
                        borderBottomWidth: 0,
                      }}
                    >
                      {emptyMessage}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            {!loading && hasNextPage && (
              <Box ref={loadingNextRef} height={20} />
            )}
            {loading && (
              <Box
                sx={{
                  position: 'absolute',
                  top: headerHeight,
                  left: 0,
                  right: 0,
                  height: 4,
                  backgroundColor: 'pink',
                  zIndex: (theme) => theme.zIndex.appBar - 1,
                }}
              >
                <LinearProgress />
              </Box>
            )}
          </TableContainer>
        </Draggable>

        <Popover
          open={openColumnVisibilityTogglePopover}
          anchorEl={columnVisibilityTogglePopoverAnchorEl}
          onClose={() => {
            setColumnVisibilityTogglePopoverAnchorEl(null);
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          elevation={1}
        >
          <List>
            <ListItem>
              <ListItemText primary={t('common:title.columnFilter')} />
              <Button
                size="small"
                onClick={() => {
                  const invisibleColumns = columnManagementOptions
                    .filter((option) => !option.enabled)
                    .map((option) => option.value);

                  setDisplayColumns((prev) => [...prev, ...invisibleColumns]);
                }}
                sx={{
                  textTransform: 'none',
                }}
                disabled={every(
                  columnManagementOptions,
                  (option) => option.enabled,
                )}
              >
                {t('common:action.enableAll')}
              </Button>
            </ListItem>
            {columnManagementOptions.map((option, index) => {
              return (
                <ListItem key={option.value}>
                  <ListItemText primary={option.label} />
                  <Box pl={2}>
                    <Switch
                      checked={option.enabled}
                      size="small"
                      onChange={() => {
                        option.enabled
                          ? setDisplayColumns((prev) =>
                              prev.filter((item) => item !== option.value),
                            )
                          : setDisplayColumns((prev) => [
                              ...prev,
                              option.value,
                            ]);
                      }}
                      disabled={option.disabled}
                    />
                  </Box>
                </ListItem>
              );
            })}
          </List>
        </Popover>
      </div>
    );
  },
);

const ColumnHeader: FC<{
  children?: React.ReactNode;
  column: DataColumn<unknown>;
}> = ({
  children,
  column: {
    label,
    sortable,
    field,
    valueGetter,
    columnActions,
    ...tableCellProps
  },
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const isHovering = useHoverDirty(ref);

  return (
    <TableCell
      ref={ref}
      key={field}
      {...tableCellProps}
      sx={{
        verticalAlign: 'top',
        p: `16px 24px 16px 16px`,
        zIndex: 15,
      }}
    >
      {children}
      {columnActions && columnActions.length > 0 && (
        <Menu>
          {({ openMenu, closeMenu, open }) => (
            <>
              {(isHovering || open) && (
                <Menu.Button>
                  <Stack
                    sx={{
                      position: 'absolute',
                      right: 0,
                      top: 16,
                      bottom: 0,
                    }}
                  >
                    <IconButton
                      className="relative w-6 h-6"
                      sx={{
                        color: (theme) => theme.palette.text.secondary,
                      }}
                      onClick={openMenu}
                    >
                      <SvgIcon>
                        <ThreeDotsVertical />
                      </SvgIcon>
                    </IconButton>
                  </Stack>
                </Menu.Button>
              )}
              <Menu.Items
                className="mt-1"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                {columnActions.map((action, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      action.onClick();
                      closeMenu();
                    }}
                  >
                    <ListItemText primary={action.label} />
                  </MenuItem>
                ))}
              </Menu.Items>
            </>
          )}
        </Menu>
      )}
    </TableCell>
  );
};

const ColumnStickyHeader = forwardRef<
  HTMLDivElement,
  { children: React.ReactNode; column: DataColumn<unknown> }
>(
  (
    {
      children,
      column: {
        label,
        sortable,
        field,
        valueGetter,
        columnActions,
        ...tableCellProps
      },
    },
    ref,
  ) => {
    const [isHovering, setIsHovering] = useState(false);

    return (
      <TableCell
        ref={ref}
        key={field}
        {...tableCellProps}
        sx={{
          ...tableCellProps.sx,
          verticalAlign: 'top',
          p: `16px 24px 16px 16px`,
        }}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        {children}
        {columnActions && columnActions.length > 0 && (
          <Menu>
            {({ openMenu, closeMenu, open }) => (
              <>
                {(isHovering || open) && (
                  <Menu.Button>
                    <Stack
                      sx={{
                        position: 'absolute',
                        right: 0,
                        top: 16,
                        bottom: 0,
                      }}
                    >
                      <IconButton
                        className="relative w-6 h-6"
                        sx={{
                          color: (theme) => theme.palette.text.secondary,
                        }}
                        onClick={openMenu}
                      >
                        <SvgIcon>
                          <ThreeDotsVertical />
                        </SvgIcon>
                      </IconButton>
                    </Stack>
                  </Menu.Button>
                )}
                <Menu.Items
                  className="mt-1"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  {columnActions.map((action, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        action.onClick();
                        closeMenu();
                      }}
                    >
                      <ListItemText primary={action.label} />
                    </MenuItem>
                  ))}
                </Menu.Items>
              </>
            )}
          </Menu>
        )}
      </TableCell>
    );
  },
);

export default DataTable;
