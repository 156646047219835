import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { Bond } from 'services/bond';

export const formatInitialism = (bondName?: string): string => {
  return (
    bondName
      ?.replace(/\s*\(.*?\)\s*/g, ' ')
      .split(/\s+/)
      // filter special characters
      .filter((n: string) => n.match(/^[a-zA-Z0-9]+$/))
      .slice(0, 2)
      .map((n: string) => n[0])
      .join('')
      .toUpperCase() ?? ''
  );
};

export const formatBondName = (bond?: Bond): string => {
  if (!bond) return '';

  const maturityDate = bond?.maturityDate;
  // check if maturityDate is over year 2099
  const isOverYear2099 = dayjs(maturityDate).isAfter('2098-12-31');
  const maturityDateFormatted = isOverYear2099
    ? 'PERP'
    : dayjs(maturityDate).format('DD MMM YYYY');

  const coupon = isNil(bond?.coupon) ? '-%' : `${bond?.coupon}%`;

  return `${bond?.ticker} ${coupon} ${maturityDateFormatted} (${bond?.currencyCode})`;
};

export const encodeIsin = (isin: string) => {
  if (!isin) return '-';
  const Length = isin.length;
  const getLastWords = isin.slice(Length - 3, Length);
  return `*********${getLastWords}`;
};

export const encodeString = (str: string, keeped = 1) => {
  if (!str) return '-';
  const stringLength = str.length;

  const getFirstWords = str.slice(0, keeped);
  const encodedString = getFirstWords.padEnd(stringLength, '*');

  return encodedString;
};

export const encodeRedirectUrl = (url: string) => {
  const encodedRedirectUrl = url.replace(/&/g, '%26');

  return encodedRedirectUrl;
};

export const pathWithRedirect = (url: string, redirect?: string) => {
  const encodedRedirectUrl = redirect?.replace(/&/g, '%26');
  return encodedRedirectUrl ? `${url}?redirect-url=${encodedRedirectUrl}` : url;
};

export const phoneNumberFormat = (
  phoneNumber: string,
  countryCallingCode: string,
) => {
  // remove space, dash, and parentheses
  return `+${countryCallingCode}${phoneNumber}`.replace(/[^\d+]/g, '');
};
