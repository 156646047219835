import React from 'react';
import {
  CardHeader,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import { RfqDetail } from '../../../../services/brokers';
import OpenNewIcon from 'ui/icons/open-new.svg?react';

const RfqHeader: React.FC<{
  rfq: RfqDetail;
  subheader?: string;
}> = ({ rfq, subheader }) => {
  const theme = useTheme();
  const onMobile = useMediaQuery(theme.breakpoints.down('md'));

  return onMobile ? (
    <Stack alignItems={'center'} pt={6} px={2} pb={2} textAlign={'center'}>
      <Stack direction="row" spacing={1} alignItems={'center'}>
        <Typography
          variant={onMobile ? 'h6' : 'h5'}
          fontWeight={'bold'}
          gutterBottom
        >
          {rfq?.assetName ?? '-'}{' '}
          <NavLink
            to={`/app/bonds/bond-detail/${rfq.assetId}`}
            className="no-underline	text-inherit whitespace-nowrap"
            target="_blank"
          >
            <IconButton
              sx={{
                p: 0,
              }}
            >
              <OpenNewIcon width={16} />
            </IconButton>
          </NavLink>
        </Typography>
      </Stack>
      {subheader && (
        <Typography variant="subtitle2" color={'text.secondary'}>
          {subheader}
        </Typography>
      )}
    </Stack>
  ) : (
    <CardHeader
      title={rfq?.assetName ?? '-'}
      subheader={subheader}
      subheaderTypographyProps={{
        fontSize: 14,
        color: 'text.secondary',
      }}
    />
  );
};

export default RfqHeader;
