import { Stack, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RfqDetail, RfqStatus } from '../../../../services/brokers';
import CircleIcon from '@mui/icons-material/Circle';
import { useEffect, useMemo, useState } from 'react';
import { useCountDownTilDate } from 'ui/hooks';
import dayjs from 'dayjs';

const getCountDownTime = (rfq: RfqDetail | undefined) => {
  return rfq?.expiredTime ? dayjs(rfq?.expiredTime).toDate() : null;
};

const ColumnStatus: React.FC<{
  rfq: RfqDetail | undefined;
  onCountDownEnd?: () => void;
}> = ({ rfq, onCountDownEnd }) => {
  const { t } = useTranslation('rfq');
  const [countDownStarted, setCountDownStarted] = useState(false);

  const countDownTime = useMemo(() => getCountDownTime(rfq), [rfq]);

  const { time } = useCountDownTilDate(countDownTime);

  const timeString = useMemo(() => {
    const { hours, minutes, seconds } = time;

    if (hours === 0 && minutes === 0 && seconds === 0) {
      return '';
    }

    if (minutes < 0 || seconds < 0 || hours < 0) {
      return t('message.order_processing');
    }

    const hoursString = hours > 0 ? `${String(hours).padStart(2, '0')}:` : '';
    const minutesString = `${String(minutes).padStart(2, '0')}:`;
    const secondsString = `${String(seconds).padStart(2, '0')}`;

    return `${hoursString}${minutesString}${secondsString}`;
  }, [time, t]);

  useEffect(() => {
    if (time.minutes > 0 || (time.seconds > 0 && !countDownStarted)) {
      setCountDownStarted(true);
      return;
    }

    if (time.minutes === 0 && time.seconds === 0 && countDownStarted) {
      setCountDownStarted(false);
      onCountDownEnd?.();
    }
  }, [time]);

  switch (rfq?.status) {
    case RfqStatus.WaitingQuote:
      return (
        <Tooltip
          title={t('tooltip.requestWilExpired')}
          placement="top-end"
          classes={{
            tooltip: 'max-w-[70vw]',
          }}
        >
          <Stack direction={'row'} alignItems={'center'}>
            <CircleIcon sx={{ color: 'warning.main', height: '1rem' }} />
            <Typography>
              {t('options.orderStatus.waitingQuote')}{' '}
              {timeString ? `(${timeString})` : ''}
            </Typography>
          </Stack>
        </Tooltip>
      );
    case RfqStatus.Quoted:
      return (
        <Tooltip
          title={t('tooltip.quoteWilExpired')}
          placement="top-end"
          classes={{
            tooltip: 'max-w-[70vw]',
          }}
        >
          <Stack direction={'row'} alignItems={'center'}>
            <CircleIcon sx={{ color: 'info.main', height: '1rem' }} />
            <Typography>
              {t('options.orderStatus.quoted')}{' '}
              {timeString ? `(${timeString})` : ''}
            </Typography>
          </Stack>
        </Tooltip>
      );
    case RfqStatus.OrderPlaced:
      return (
        <Tooltip
          title={t('tooltip.orderWilExpired')}
          placement="top-end"
          classes={{
            tooltip: 'max-w-[70vw]',
          }}
        >
          <Stack direction={'row'} alignItems={'center'}>
            <CircleIcon sx={{ color: 'success.main', height: '1rem' }} />
            <Typography>
              {t('options.orderStatus.orderPlaced')}{' '}
              {/* {timeString ? `(${timeString})` : ''} */}
            </Typography>
          </Stack>
        </Tooltip>
      );
    case RfqStatus.OrderUpdated:
      return (
        <Stack direction={'row'} alignItems={'center'}>
          <CircleIcon sx={{ color: 'warning.main', height: '1rem' }} />
          <Typography>
            {t('options.orderStatus.orderUpdatedWatingForConfirm')}{' '}
            {/* {timeString ? `(${timeString})` : ''} */}
          </Typography>
        </Stack>
      );
    case RfqStatus.OrderExecuted:
      return (
        <Stack direction={'row'} alignItems={'center'}>
          <CircleIcon sx={{ color: '#13C998', height: '1rem' }} />
          <Typography>{t('options.orderStatus.orderExecuted')}</Typography>
        </Stack>
      );
    case RfqStatus.OrderCancelledByBlotter:
      return (
        <Stack direction={'row'} alignItems={'center'}>
          <CircleIcon sx={{ color: '#6A707B', height: '1rem' }} />
          <Typography>{t('options.orderStatus.orderCancelled')}</Typography>
        </Stack>
      );
    case RfqStatus.Expired:
      return (
        <Stack direction={'row'} alignItems={'center'}>
          <CircleIcon sx={{ color: '#6A707B', height: '1rem' }} />
          <Typography>{t('options.orderStatus.expired')}</Typography>
        </Stack>
      );
    case RfqStatus.ClientRejected:
      return (
        <Stack direction={'row'} alignItems={'center'}>
          <CircleIcon sx={{ color: '#6A707B', height: '1rem' }} />
          <Typography>{t('options.orderStatus.clientRejected')}</Typography>
        </Stack>
      );
    default:
      return null;
  }
};

export default ColumnStatus;
