/* eslint-disable react-hooks/rules-of-hooks */
import {
  useAddBrokerAccount as useAddBrokerAccountService,
  useAddRFQ as useAddRFQService,
  useBrokers as useBrokersService,
  useCancelRFQ as useCancelRFQService,
  useDeleteBroker as useDeleteBrokerService,
  useGetOrderFeedback as useGetOrderFeedbackService,
  useGetQuote as useGetQuoteService,
  useIndex as useIndexService,
  useIndexInfinite as useIndexInfiniteService,
  useRfqDetail as useRfqDetailService,
  usePlaceQuote as usePlaceQuoteService,
  useRFQOrder as useRFQOrderService,
  useRFQQuotes as useRFQQuotesService,
  useRfqIndexInfinite as useRfqIndexInfiniteService,
  useSubmitOrderFeedback as useSubmitOrderFeedbackService,
  useSubmitQuote as useSubmitQuoteService,
  useSummary as useSummaryService,
  useUncancelRFQ as useUncancelRFQService,
  useUploadBrokerStatement as useUploadBrokerStatementService,
  userRfqIndex as userRfqIndexService,
  useUpdateRfqReadStatus as useUpdateRfqReadStatusService,
  useUpdateQuote as useUpdateQuoteService,
  useCancelQuote as useCancelQuoteService,
  useResponseUpdateOrder as useResponseUpdateOrderService,
  useUpdateQuoteMeta as useUpdateBrokerSpreadService,
  useBlottersRfqStatusOptions,
  useOrdersRfqStatusOptions,
  RfqStatus,
  OrderFeedbackStatus,
  OrderFeedbackConfirmStatus,
  RfqDetail,
} from 'services/broker';
import { apiAuthClient, apiBaseClient } from '../services/apiClient';

export {
  useBlottersRfqStatusOptions,
  useOrdersRfqStatusOptions,
  RfqStatus,
  OrderFeedbackStatus,
  OrderFeedbackConfirmStatus,
};
export type { RfqDetail };

export const useAddBrokerAccount = useAddBrokerAccountService(apiAuthClient());
export const useAddRFQ = useAddRFQService(apiAuthClient());
export const useBrokers = useBrokersService(apiAuthClient());
export const useCancelRFQ = useCancelRFQService(apiAuthClient());
export const useDeleteBroker = useDeleteBrokerService(apiAuthClient());
export const useGetOrderFeedback = useGetOrderFeedbackService(apiBaseClient());
export const useGetQuote = useGetQuoteService(apiBaseClient());
export const useIndex = useIndexService(apiAuthClient());
export const useIndexInfinite = useIndexInfiniteService(apiAuthClient());
export const useRfqDetail = useRfqDetailService(apiAuthClient());
export const usePlaceQuote = usePlaceQuoteService(apiAuthClient());
export const useRFQOrder = useRFQOrderService(apiAuthClient());
export const useRFQQuotes = useRFQQuotesService(apiAuthClient());
export const useRfqIndexInfinite = useRfqIndexInfiniteService(apiAuthClient());
export const useSubmitOrderFeedback =
  useSubmitOrderFeedbackService(apiBaseClient());
export const useSubmitQuote = useSubmitQuoteService(apiBaseClient());
export const useSummary = useSummaryService(apiAuthClient());
export const useUncancelRFQ = useUncancelRFQService(apiAuthClient());
export const useUploadBrokerStatement =
  useUploadBrokerStatementService(apiAuthClient());
export const userRfqIndex = userRfqIndexService(apiAuthClient());
export const useUpdateRfqReadStatus =
  useUpdateRfqReadStatusService(apiAuthClient());
export const useUpdateQuote = useUpdateQuoteService(apiAuthClient());
export const useCancelQuote = useCancelQuoteService(apiAuthClient());
export const useResponseUpdateOrder =
  useResponseUpdateOrderService(apiAuthClient());
export const useUpdateQuoteMeta = useUpdateBrokerSpreadService(apiAuthClient());
