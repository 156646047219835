import { Chip, chipClasses, Stack, Typography } from '@mui/material';
import { RfqDetail, RfqStatus } from 'services/broker';
import { thousandSeparated } from 'ui/utils';
import { useTranslation } from 'react-i18next';

const ColumnPrice: React.FC<{
  rfq: RfqDetail | undefined;
}> = ({ rfq }) => {
  const { t } = useTranslation('rfq');

  switch (true) {
    case rfq?.status === RfqStatus.WaitingQuote && Boolean(rfq?.bestQuote):
      return thousandSeparated(rfq?.bestQuote);
    case rfq?.price && rfq?.orderPrice && rfq?.price !== rfq?.orderPrice:
      return (
        <Stack direction="row" spacing={1}>
          <Chip
            label={t('common:option.old')}
            size="small"
            sx={{
              textTransform: 'uppercase',
              [`& .${chipClasses.root}`]: {
                backgroundColor: '#232525',
              },
              [`& .${chipClasses.label}`]: {
                color: 'text.secondary',
              },
            }}
          />
          <Typography
            color={'textSecondary'}
            component={'span'}
            sx={{
              textDecoration: 'line-through',
            }}
          >
            {thousandSeparated(rfq?.price)}
          </Typography>
          <Chip
            label={t('common:option.new')}
            color="primary"
            size="small"
            sx={{
              textTransform: 'uppercase',
            }}
          />
          <Typography component={'span'}>
            {thousandSeparated(rfq?.orderPrice)}
          </Typography>
        </Stack>
      );
    case Boolean(rfq?.price):
      return thousandSeparated(rfq?.price);
    default:
      return '-';
  }
};

export default ColumnPrice;
