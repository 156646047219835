import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import type {
  UseInfiniteQueryOptions,
  UseMutationOptions,
  UseQueryOptions,
} from '@tanstack/react-query';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { ApiConfig, ApiKey } from './config';

export interface RfqDetail {
  id: number;
  username: string;
  fullName: string;
  assetType: string;
  assetId: number;
  assetIdentifier: string;
  assetName: string;
  currency: string;
  size: number;
  initSize: number | null;
  createdAt: string;
  updatedAt: string;
  direction: string;
  status: number;
  issuerName: string;
  quote: number;
  price: number | null;
  platformFee: number;
  bestQuote: number | null;
  bestQuotedTime: string | null;
  bestQuoteId: number | null;
  bestQuoteFee: number | null;
  bestSettlementDate: string | null;
  quoteId: number;
  quoteSize: number;
  quotedTime: string;
  quoteFee: number;
  cancellable: boolean;
  tradeAccountFi: string;
  tradeAccountCode: string;
  rmEmail: string;
  traderName: string;
  traderEmail: string;
  orderId: number;
  orderPrice: number;
  orderSize: number;
  prevOrderPrice: number | null;
  prevOrderSize: number | null;
  orderRequestTime: string;
  isRead: boolean;
  reQuoteChance: number;
  isPartialFill: boolean;
  orderNote?: string;
  quoteStatus?: number;
  settlementDate?: string | null;
  brokerSpread?: number;
  expiredTime?: string | null;
}

interface GetRfqDetailParam {
  view?: 1 | 0;
}

interface GetRfqDetailResponse extends RfqDetail {}

export const useRfqDetail =
  (axiosClient: AxiosInstance) =>
  (
    id: string,
    params: GetRfqDetailParam,
    options?: UseQueryOptions<
      GetRfqDetailResponse,
      unknown,
      GetRfqDetailResponse,
      (string | GetRfqDetailParam)[]
    >,
  ) =>
    useQuery({
      queryKey: [ApiKey, 'detail', id],
      queryFn: () =>
        axiosClient
          .get(ApiConfig.rfqDetail(id), {
            params,
          })
          .then((res) => res.data),
      ...options,
    });

type IndexResponse = {
  content: {
    id: number;
    [key: string]: any;
  }[];
  totalItems: number;
  totalPages: number;
  pageNumber: number;
  pageSize: number;
};
interface GetBrokersListParam {
  page?: number;
  size?: number;
  [key: string]: any;
}

export const useIndex =
  (axiosClient: AxiosInstance) =>
  (
    params: GetBrokersListParam,
    options?: UseQueryOptions<
      IndexResponse,
      unknown,
      IndexResponse,
      (string | GetBrokersListParam)[]
    >,
  ) =>
    useQuery({
      queryKey: [ApiKey, 'index', params],
      queryFn: () =>
        axiosClient
          .get(ApiConfig.index, {
            params,
          })
          .then((res) => res.data),
      ...options,
    });

export const useIndexInfinite =
  (axiosClient: AxiosInstance) =>
  (
    params: GetBrokersListParam,
    options?: UseInfiniteQueryOptions<
      IndexResponse,
      unknown,
      IndexResponse,
      IndexResponse,
      (string | GetBrokersListParam)[]
    >,
  ) =>
    useInfiniteQuery({
      queryKey: [ApiKey, 'index', params],
      queryFn: ({ pageParam = 1 }) =>
        axiosClient
          .get(ApiConfig.index, {
            params: {
              ...params,
              page: pageParam,
            },
          })
          .then((res) => res.data),
      getNextPageParam: (lastPage, pages) => {
        if (!lastPage) return;

        const nextPage = pages.length + 1;
        if (nextPage > lastPage.totalPages) return;

        return nextPage;
      },
      ...options,
    });

interface GetSummaryParam {
  [key: string]: any;
}

interface GetSummaryResponse {
  waitingForQuote: number | null;
  orderPlaced: number | null;
  quoteIsReady: number | null;
  orderExecuted: number | null;
  orderUpdated: number | null;
  orderRejected: number | null;
  orderCancelledByBlotter: number | null;
  total: number | null;
}

export const useSummary =
  (axiosClient: AxiosInstance) =>
  (
    params: GetSummaryParam,
    options?: UseQueryOptions<
      GetSummaryResponse,
      unknown,
      GetSummaryResponse,
      (string | GetSummaryParam)[]
    >,
  ) =>
    useQuery({
      queryKey: [ApiKey, 'summary', params],
      queryFn: () =>
        axiosClient
          .get(ApiConfig.summary, {
            params,
          })
          .then((res) => res.data),
      refetchOnWindowFocus: true,
      ...options,
    });

type RfqIndexItem = RfqDetail;

type SearchRFQResponse = {
  content: RfqIndexItem[];
  totalElements: number;
  totalPages: number;
  pageNumber: number;
  pageSize: number;
};

interface SearchRFQParam {
  page?: number;
  size?: number;
  view?: number;
  [key: string]: any;
}

export const userRfqIndex =
  (axiosClient: AxiosInstance) =>
  (
    params: SearchRFQParam,
    options?: UseQueryOptions<
      SearchRFQResponse,
      unknown,
      SearchRFQResponse,
      (string | SearchRFQParam)[]
    >,
  ) =>
    useQuery({
      queryKey: [ApiKey, 'searchRfq', params],
      queryFn: () =>
        axiosClient
          .get(ApiConfig.searchRFQ, {
            params,
          })
          .then((res) => res.data),
      refetchOnWindowFocus: true,
      ...options,
    });

export const useRfqIndexInfinite =
  (axiosClient: AxiosInstance) =>
  (
    params: SearchRFQParam,
    options?: UseInfiniteQueryOptions<
      SearchRFQResponse,
      unknown,
      SearchRFQResponse,
      SearchRFQResponse,
      (string | SearchRFQParam)[]
    >,
  ) =>
    useInfiniteQuery({
      queryKey: [ApiKey, 'searchRfqInfinite', params],
      queryFn: ({ pageParam = 1 }) =>
        axiosClient
          .get(ApiConfig.searchRFQ, {
            params: {
              ...params,
              page: pageParam,
            },
          })
          .then((res) => res.data),
      getNextPageParam: (lastPage, pages) => {
        if (!lastPage) return;

        const nextPage = pages.length + 1;
        if (nextPage > lastPage.totalPages) return;

        return nextPage;
      },
      ...options,
    });

const statusRFQ = {
  0: 'Select Quote',
  1: 'Waiting order to be executed',
  2: 'Order executed',
  3: 'Order cancelled',
};

export const formatTradeStatus = (status: keyof typeof statusRFQ) => {
  return statusRFQ[status];
};

export const tradeStatusOptions = Object.entries(statusRFQ).map(
  ([key, value]) => ({
    label: value,
    value: key,
  }),
);

type BrokerResponse = {
  id: number;
  name: string;
  tradingDeskEmail: string;
  isPartner: boolean;
  daysOfNotification: number;
}[];

export const useBrokers =
  (axiosClient: AxiosInstance) =>
  (
    options?: UseQueryOptions<
      BrokerResponse,
      AxiosError,
      BrokerResponse,
      string[]
    >,
  ) =>
    useQuery({
      queryKey: [ApiKey, 'brokers'],
      queryFn: () => axiosClient.get(ApiConfig.brokers).then((res) => res.data),
      ...options,
    });

type AddRFQParam = {
  assetId: number;
  assetType: string;
  direction: string;
  size: number;
  isPartialFill: boolean;
};

type AddRFQResponse = {
  id: number;
  direction: string;
  currency: string;
  size: number;
  price: number;
  fromBroker?: string;
  fromBrokerId?: number;
};

export const useAddRFQ =
  (axiosClient: AxiosInstance) =>
  (
    options?: UseMutationOptions<
      AxiosResponse<AddRFQResponse>,
      AxiosError<{
        message: string;
      }>,
      AddRFQParam,
      (string | AddRFQParam)[]
    >,
  ) => {
    const queryClient = useQueryClient();

    return useMutation({
      mutationFn: (params: AddRFQParam) =>
        axiosClient.post(ApiConfig.addRFQ, params),
      ...options,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries([ApiKey, 'summary']);
        queryClient.invalidateQueries([ApiKey, 'searchRfq']);
        queryClient.invalidateQueries([ApiKey, 'searchRfqInfinite']);

        options?.onSuccess?.(data, variables, context);
      },
    });
  };

type RFQQuotesResponse = {
  rfqId: number;
  quoteId: number;
  fiId: number;
  fiName: string;
  price: number;
  createdAt: string;
}[];

export const useRFQQuotes =
  (axiosClient: AxiosInstance) =>
  (
    id: string,
    options?: UseQueryOptions<
      RFQQuotesResponse,
      AxiosError,
      RFQQuotesResponse,
      string[]
    >,
  ) =>
    useQuery({
      queryKey: [ApiKey, 'rfqQuotes', id],
      queryFn: () =>
        axiosClient.get(ApiConfig.rfqQuotes(id)).then((res) => res.data),
      ...options,
    });

type RFQOrderResponse = {
  message: string;
};

type RFQOrderParam = {
  rfqId: number;
  defaultNextTime: boolean;
  fiAccountId: number;
  quoteId: number;
  price: number;
};

export const useRFQOrder =
  (axiosClient: AxiosInstance) =>
  (
    options?: UseMutationOptions<
      AxiosResponse<RFQOrderResponse>,
      AxiosError,
      RFQOrderParam,
      (string | RFQOrderParam)[]
    >,
  ) => {
    const queryClient = useQueryClient();

    return useMutation({
      mutationFn: ({ rfqId, ...params }: RFQOrderParam) =>
        axiosClient.post(ApiConfig.rfqOrder(String(rfqId)), params),
      ...options,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries([ApiKey, 'summary']);
        queryClient.invalidateQueries([ApiKey, 'searchRfq']);
        queryClient.invalidateQueries([ApiKey, 'searchRfqInfinite']);
        queryClient.invalidateQueries([
          ApiKey,
          'detail',
          variables.rfqId.toString(),
        ]);

        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    });
  };

type GetQuoteParams = {
  token: string;
};

type GetQuoteResponse = {
  price: number;
  token: string;
};

export const useGetQuote =
  (axiosClient: AxiosInstance) =>
  (
    options?: UseMutationOptions<
      AxiosResponse<GetQuoteResponse>,
      AxiosError,
      GetQuoteParams,
      (string | GetQuoteParams)[]
    >,
  ) =>
    useMutation({
      mutationFn: (params: GetQuoteParams) =>
        axiosClient.get(ApiConfig.getQuote(), { params }),
      ...options,
    });

type SubmitQuoteParams = {
  token: string;
  price: number;
};

type SubmitQuoteResponse = {
  price: number;
  token: string;
};

export const useSubmitQuote =
  (axiosClient: AxiosInstance) =>
  (
    options?: UseMutationOptions<
      AxiosResponse<SubmitQuoteResponse>,
      AxiosError,
      SubmitQuoteParams,
      (string | SubmitQuoteParams)[]
    >,
  ) =>
    useMutation({
      mutationFn: (params: SubmitQuoteParams) =>
        axiosClient.post(ApiConfig.submitQuote(), params),
      ...options,
    });

type GetOrderFeedbackParams = {
  token: string;
};

type GetOrderFeedbackResponse = {
  status: string;
  size: string;
};

export const useGetOrderFeedback =
  (axiosClient: AxiosInstance) =>
  (
    options?: UseMutationOptions<
      AxiosResponse<GetOrderFeedbackResponse>,
      AxiosError,
      GetOrderFeedbackParams,
      (string | GetOrderFeedbackParams)[]
    >,
  ) =>
    useMutation({
      mutationFn: (params: GetOrderFeedbackParams) =>
        axiosClient.get(ApiConfig.getOrderFeedback(), { params }),
      ...options,
    });

export enum OrderFeedbackStatus {
  Executed = 1,
  CanceledByBroker = 2,
  Updated = 3,
}

type SubmitOrderFeedbackParams = {
  rfqId: string;
  token?: string;
  status: OrderFeedbackStatus;
  size?: string | null;
  price?: string | null;
  executedAt?: string | null;
  executedSize?: number | null;
  executePrice?: number | null;
  notes?: string;
};

type SubmitOrderFeedbackResponse = {
  status: OrderFeedbackStatus;
  executedAt: string;
  notes: string;
};

export const useSubmitOrderFeedback =
  (axiosClient: AxiosInstance) =>
  (
    options?: UseMutationOptions<
      AxiosResponse<SubmitOrderFeedbackResponse>,
      AxiosError<{
        error: {
          message: string;
        };
      }>,
      SubmitOrderFeedbackParams,
      (string | SubmitOrderFeedbackParams)[]
    >,
  ) => {
    const queryClient = useQueryClient();

    return useMutation({
      mutationFn: ({ rfqId, ...params }: SubmitOrderFeedbackParams) =>
        axiosClient.post(ApiConfig.submitOrderFeedback(rfqId), params),
      ...options,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries([ApiKey, 'summary']);
        queryClient.invalidateQueries([ApiKey, 'searchRfq']);
        queryClient.invalidateQueries([ApiKey, 'searchRfqInfinite']);
        queryClient.invalidateQueries([
          ApiKey,
          'detail',
          variables.rfqId.toString(),
        ]);

        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    });
  };

interface AddBrokerAccountParams {
  fi: string;
  accountName: string;
  accountCode: string;
  rmEmail: string;
}

type AddBrokerAccountResponse = {
  id: number;
  [key: string]: any;
};

export const useAddBrokerAccount =
  (axiosClient: AxiosInstance) =>
  (
    options?: UseMutationOptions<
      AxiosResponse<AddBrokerAccountResponse>,
      AxiosError,
      AddBrokerAccountParams,
      (string | AddBrokerAccountParams)[]
    >,
  ) => {
    const queryClient = useQueryClient();

    return useMutation({
      mutationFn: (params: AddBrokerAccountParams) =>
        axiosClient.post(ApiConfig.addBrokerAccount(), params),
      ...options,
      onSuccess: (...params) => {
        queryClient.invalidateQueries([ApiKey, 'brokers']);

        options?.onSuccess?.(...params);
      },
    });
  };

type UploadBrokerStatementParams = {
  id: string;
  file: File;
};

type UploadBrokerStatementResponse = {
  id: number;
  [key: string]: any;
};

export const useUploadBrokerStatement =
  (axiosClient: AxiosInstance) =>
  (
    options?: UseMutationOptions<
      AxiosResponse<UploadBrokerStatementResponse>,
      AxiosError,
      UploadBrokerStatementParams,
      (string | UploadBrokerStatementParams)[]
    >,
  ) =>
    useMutation({
      mutationFn: ({ id, file }: UploadBrokerStatementParams) => {
        let formData = new FormData();
        formData.append('file', file);

        return axiosClient.post(
          ApiConfig.uploadBrokerAccountStatement(id),
          formData,
        );
      },
      ...options,
    });

type DeleteBrokerParams = {
  id: string;
};

type DeleteBrokerResponse = {
  id: number;
  [key: string]: any;
};

export const useDeleteBroker =
  (axiosClient: AxiosInstance) =>
  (
    options?: UseMutationOptions<
      AxiosResponse<DeleteBrokerResponse>,
      AxiosError<{
        error: {
          message: string;
        };
      }>,
      DeleteBrokerParams,
      (string | DeleteBrokerParams)[]
    >,
  ) => {
    const queryClient = useQueryClient();

    return useMutation({
      mutationFn: ({ id }: DeleteBrokerParams) =>
        axiosClient.delete(ApiConfig.deleteBrokers(id)),
      ...options,
      onSuccess: (...params) => {
        queryClient.invalidateQueries([ApiKey, 'brokers']);

        options?.onSuccess?.(...params);
      },
    });
  };

interface CancelRFQParams {
  id: string;
  note?: string;
}

export const useCancelRFQ =
  (axiosClient: AxiosInstance) =>
  (
    options?: UseMutationOptions<
      AxiosResponse<DeleteBrokerResponse>,
      AxiosError<{
        message: string;
      }>,
      CancelRFQParams,
      (string | CancelRFQParams)[]
    >,
  ) => {
    const queryClient = useQueryClient();

    return useMutation({
      mutationFn: ({ id }: CancelRFQParams) =>
        axiosClient.post(ApiConfig.cancelRFQ(id)),
      ...options,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries([ApiKey, 'summary']);
        queryClient.invalidateQueries([ApiKey, 'searchRfq']);
        queryClient.invalidateQueries([ApiKey, 'searchRfqInfinite']);
        queryClient.invalidateQueries([
          ApiKey,
          'detail',
          variables.id.toString(),
        ]);

        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    });
  };

interface UncancelRFQParams {
  id: string;
}

export const useUncancelRFQ =
  (axiosClient: AxiosInstance) =>
  (
    options?: UseMutationOptions<
      AxiosResponse<DeleteBrokerResponse>,
      AxiosError,
      UncancelRFQParams,
      (string | UncancelRFQParams)[]
    >,
  ) => {
    const queryClient = useQueryClient();

    return useMutation({
      mutationFn: ({ id }: UncancelRFQParams) =>
        axiosClient.post(ApiConfig.uncancelRFQ(id)),
      ...options,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries([ApiKey, 'summary']);
        queryClient.invalidateQueries([ApiKey, 'searchRfq']);
        queryClient.invalidateQueries([ApiKey, 'searchRfqInfinite']);
        queryClient.invalidateQueries([
          ApiKey,
          'detail',
          variables.id.toString(),
        ]);

        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    });
  };

interface PlaceQuoteParams {
  id: string;
  price: number;
  size?: number;
  brokerSpread?: number;
}

export const usePlaceQuote =
  (axiosClient: AxiosInstance) =>
  (
    options?: UseMutationOptions<
      AxiosResponse,
      AxiosError,
      PlaceQuoteParams,
      (string | PlaceQuoteParams)[]
    >,
  ) => {
    const queryClient = useQueryClient();

    return useMutation({
      mutationFn: ({ id, ...payload }: PlaceQuoteParams) =>
        axiosClient.post(ApiConfig.placeQuote(id), { ...payload }),
      ...options,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries([ApiKey, 'summary']);
        queryClient.invalidateQueries([ApiKey, 'searchRfq']);
        queryClient.invalidateQueries([ApiKey, 'searchRfqInfinite']);
        queryClient.invalidateQueries([
          ApiKey,
          'detail',
          variables.id.toString(),
        ]);

        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    });
  };

interface UpdateRfqReadStatusParams {
  isRead: boolean;
  rfqId: string;
}

export const useUpdateRfqReadStatus =
  (axiosClient: AxiosInstance) =>
  (
    options?: UseMutationOptions<
      AxiosResponse,
      AxiosError,
      UpdateRfqReadStatusParams,
      (string | UpdateRfqReadStatusParams)[]
    >,
  ) => {
    const queryClient = useQueryClient();

    return useMutation({
      mutationFn: ({ isRead, rfqId }: UpdateRfqReadStatusParams) =>
        axiosClient.patch(ApiConfig.updateRfqReadStatus(rfqId), { isRead }),
      ...options,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries([ApiKey, 'summary']);
        queryClient.invalidateQueries([ApiKey, 'searchRfq']);
        queryClient.invalidateQueries([ApiKey, 'searchRfqInfinite']);
        queryClient.invalidateQueries([
          ApiKey,
          'detail',
          variables.rfqId.toString,
        ]);

        options?.onSuccess?.(data, variables, context);
      },
    });
  };

interface UpdateQuoteParams {
  rfqId: string;
  quoteId: string;
  price: number;
  size?: number;
}

export const useUpdateQuote =
  (axiosClient: AxiosInstance) =>
  (
    options?: UseMutationOptions<
      AxiosResponse,
      AxiosError,
      UpdateQuoteParams,
      (string | UpdateQuoteParams)[]
    >,
  ) => {
    const queryClient = useQueryClient();

    return useMutation({
      mutationFn: ({ rfqId, quoteId, ...payload }: UpdateQuoteParams) =>
        axiosClient.patch(ApiConfig.updateQuote(rfqId, quoteId), {
          ...payload,
        }),
      ...options,
      onSuccess: (data, variables, ...params) => {
        queryClient.invalidateQueries([ApiKey, 'detail', variables.rfqId]);

        options?.onSuccess?.(data, variables, ...params);
      },
    });
  };

interface CancelQuoteParams {
  rfqId: string;
  quoteId: string;
}

export const useCancelQuote =
  (axiosClient: AxiosInstance) =>
  (
    options?: UseMutationOptions<
      AxiosResponse,
      AxiosError,
      CancelQuoteParams,
      (string | CancelQuoteParams)[]
    >,
  ) => {
    const queryClient = useQueryClient();

    return useMutation({
      mutationFn: ({ rfqId, quoteId }: CancelQuoteParams) =>
        axiosClient.post(ApiConfig.cancelQuote(rfqId, quoteId)),
      ...options,
      onSuccess: (data, variables, ...params) => {
        queryClient.invalidateQueries([ApiKey, 'detail', variables.rfqId]);
        queryClient.invalidateQueries([ApiKey, 'searchRfq']);
        queryClient.invalidateQueries([ApiKey, 'searchRfqInfinite']);

        options?.onSuccess?.(data, variables, ...params);
      },
    });
  };

export enum OrderFeedbackConfirmStatus {
  Confirmed = 5,
  Rejected = 2,
}

interface ResponseOrderUpdateParams {
  rfqId: string;
  orderId: string;
  status: OrderFeedbackConfirmStatus;
  executedAt: string;
}

export const useResponseUpdateOrder =
  (axiosClient: AxiosInstance) =>
  (
    options?: UseMutationOptions<
      AxiosResponse,
      AxiosError<{
        error: {
          message: string;
        };
      }>,
      ResponseOrderUpdateParams,
      (string | ResponseOrderUpdateParams)[]
    >,
  ) => {
    const queryClient = useQueryClient();

    return useMutation({
      mutationFn: (params: ResponseOrderUpdateParams) =>
        axiosClient.patch(
          ApiConfig.updateOrder(params.rfqId, params.orderId),
          params,
        ),
      ...options,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries([ApiKey, 'summary']);
        queryClient.invalidateQueries([ApiKey, 'searchRfq']);
        queryClient.invalidateQueries([ApiKey, 'searchRfqInfinite']);
        queryClient.invalidateQueries([ApiKey, 'detail', variables.rfqId]);

        options?.onSuccess?.(data, variables, context);
      },
    });
  };

interface UpdateQuoteMetaParams {
  rfqId: string | number;
  quoteId: string | number;
  brokerSpread?: number;
  settlementDate?: string;
}

interface UpdateQuoteMetaResponse {
  brokerSpread: number;
}

export const useUpdateQuoteMeta =
  (axiosClient: AxiosInstance) =>
  (
    options?: UseMutationOptions<
      AxiosResponse<UpdateQuoteMetaResponse>,
      AxiosError,
      UpdateQuoteMetaParams,
      (string | UpdateQuoteMetaParams)[]
    >,
  ) => {
    const queryClient = useQueryClient();

    return useMutation({
      mutationFn: ({ rfqId, quoteId, ...meta }: UpdateQuoteMetaParams) =>
        axiosClient.patch(
          ApiConfig.updateQuoteMeta(rfqId.toString(), quoteId.toString()),
          { ...meta },
        ),
      ...options,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries([
          ApiKey,
          'detail',
          variables.rfqId.toString(),
        ]);

        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    });
  };
