import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Divider,
  Grid,
  Skeleton,
  Stack,
  SvgIcon,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  RfqDetail,
  RfqStatus,
  usePlaceQuote,
  useRfqDetail,
} from '../../../../services/brokers';
import { ModalLayout, useToast } from 'ui/components';
import ActionFetchRfqDetail from '../../components/ActionFetchRfqDetail';
import TypographyWithLoading from '../../components/TypographyWithLoading';
import BaseActionDialog from 'ui/components/modal/BaseActionDialog';
import { dateFormat, thousandSeparated } from 'ui/utils';
import { isNil } from 'lodash';
import InformationMark from 'ui/components/InformationMark';
import WarningTriangleIcon from 'ui/icons/warning_triangle.svg?react';
import RfqHeader from '../../orders/components/RfqHeader';
import ColumnStatus from './ColumnStatus';
import ColumnSize from '../../components/ColumnSize';
import ColumnCreatedAt from '../../components/ColumnCreatedAt';
import ColumnId from '../../components/ColumnId';
import ColumnDirection from '../../components/ColumnDirection';
import DetailLabel from '../../components/DetailLabel';
import InputPartialFillSize from './InputPartialFillSize';
import InputPrice from './InputPrice';
import { useIndex } from '../../../../services/bonds';
import priceOutOfMidPriceRangeAlertText from '../../components/priceOutOfMidPriceRangeAlertText';
import {
  Services,
  useTraderServiceIsDisabled,
} from '../../../../config/serviceIsDisabled';
import { useMeasure } from 'react-use';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import InputSettlementDate from './InputSettlementDate';
import { formatApiErrorToast } from '../../../../services/apiUtils';
dayjs.extend(utc);
dayjs.extend(timezone);

interface FormValues {
  price: number | undefined;
  size: number | undefined;
  brokerSpread: number | undefined;
  settlementDate: dayjs.Dayjs | undefined;
}

const analyticsMetaProps = {
  'data-ga-event': 'action_lay_quote_init',
  'data-ga-category': 'trade',
  'data-testid': 'action-lay-quote',
};
const ActionLayQuote: React.FC<{
  children: (
    props: {
      onClick: () => void;
      disabled?: boolean;
    } & typeof analyticsMetaProps,
  ) => JSX.Element;
  onSuccess?: () => void;
  onOpen?: () => void;
  onClose?: () => void;
  rfq: RfqDetail;
}> = ({ children, onSuccess, onOpen, onClose: onCloseProp, rfq }) => {
  const { t } = useTranslation(['rfq', 'validation']);
  const theme = useTheme();
  const onMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = useState(false);
  const [priceConfirmed, setPriceConfirmed] = useState(false);
  const toast = useToast();
  const [headerContainerRef, { height: headerHeight }] = useMeasure();

  const brokerSpreadDisabled = useTraderServiceIsDisabled(
    Services.TRADE_RFQ_BROKER_SPREAD,
  );

  const { data, refetch: refetchRfqDetail } = useRfqDetail(
    String(rfq?.id),
    {
      view: 1,
    },
    {
      enabled: open,
      refetchOnWindowFocus: true,
      keepPreviousData: true,
      cacheTime: 0,
    },
  );
  const { data: bondIndex } = useIndex(
    {
      page: 1,
      size: 1,
      isinList: rfq?.assetIdentifier,
    },
    {
      enabled: open && Boolean(rfq?.assetIdentifier),
    },
  );
  const bondDetail = useMemo(() => bondIndex?.content[0], [bondIndex]);

  const currentBestPrice = useMemo(() => {
    if (data?.direction === 'BUY') {
      return data?.bestQuote || Infinity;
    } else {
      return data?.bestQuote || 0;
    }
  }, [data?.bestQuote, data?.direction]);
  const ableToQuote = useMemo(() => {
    return Boolean(!data?.quoteId) || data?.reQuoteChance;
  }, [data?.quoteId, data?.reQuoteChance]);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        price: yup
          .number()
          .required()
          .label(t('field.price').toLowerCase())
          .moreThan(0, t('validation:custom.greaterThanZero')),
        size: yup
          .number()
          .optional()
          .label(t('field.size').toLowerCase())
          .test(
            'requiredIfPartialFill',
            t('validation:custom.requiredIfPartialFill'),
            (value) => {
              if (!data?.isPartialFill) return true;
              return !isNil(value);
            },
          )
          .moreThan(0, t('validation:custom.greaterThanZero'))
          .test(
            'shouldNotExceed',
            t('validation:custom.shouldNotExceed', {
              size: data?.initSize,
            }),
            (value) => {
              if (!data?.initSize) return true;
              if (!value) return true;
              return value <= data?.initSize;
            },
          ),
        brokerSpread: yup
          .number()
          .optional()
          .nullable()
          .label(t('field.brokerSpread').toLowerCase()),
        settlementDate: yup.date().required(),
      }),
    [t, data?.direction, currentBestPrice, data?.initSize],
  );

  const getSettlementDate = useCallback(() => {
    const isUsBond =
      data?.assetIdentifier?.startsWith('US') ||
      data?.assetIdentifier?.startsWith('CA') ||
      false;

    const defaultDateDiff = isUsBond ? 1 : 2;

    let date = dayjs()
      .tz('Asia/Hong_Kong')
      .isAfter(dayjs().tz('Asia/Hong_Kong').startOf('day').add(18, 'hours'))
      ? dayjs()
          .tz('Asia/Hong_Kong')
          .endOf('day')
          .add(defaultDateDiff + 1, 'day')
      : dayjs().tz('Asia/Hong_Kong').endOf('day').add(defaultDateDiff, 'day');

    while (date.day() === 0 || date.day() === 6) {
      date = date.add(1, 'day');
    }

    return date;
  }, [data]);

  const defaultValues: FormValues = useMemo(() => {
    return {
      price: undefined,
      brokerSpread: 0,
      size: data?.size,
      settlementDate: getSettlementDate(),
    };
  }, [data?.size]);

  const { control, handleSubmit, reset, watch } = useForm<FormValues>({
    defaultValues,
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });
  const formValues = watch();
  const isFormValuesValid = useMemo(() => {
    return validationSchema.isValidSync(formValues);
  }, [formValues]);

  const sizeHelperText = useMemo(() => {
    if (
      data?.isPartialFill &&
      formValues.size &&
      formValues.size < data?.size
    ) {
      return t('tooltip.partialFillSizeWillBeLowPriority');
    }
    return '';
  }, [data?.isPartialFill, formValues.size, data?.size]);

  const priceHelperText = useMemo(() => {
    // If offered price is out of mid price +- 40% range, show alert
    if (formValues.price && bondDetail?.midPriceClean) {
      const alert = priceOutOfMidPriceRangeAlertText(
        bondDetail,
        formValues.price,
        t,
      );

      if (alert) {
        return alert;
      }
    }

    if (data?.bestQuote && formValues.price) {
      switch (true) {
        case data?.direction === 'BUY' &&
          Number(formValues.price) >= data?.bestQuote:
          return t('tooltip.theresABetterPrice');

        case data?.direction === 'SELL' &&
          Number(formValues.price) <= data?.bestQuote:
          return t('tooltip.theresABetterPrice');
      }
    }

    return '';
  }, [
    data?.bestQuote,
    formValues.price,
    data?.direction,
    bondDetail?.midPriceClean,
  ]);

  const handleClick = () => {
    setOpen(true);
  };

  const onClose = () => {
    reset();
    setOpen(false);
    setPriceConfirmed(false);
    onMobile
      ? setTimeout(() => {
          onCloseProp?.();
        }, theme.transitions.duration.standard)
      : onCloseProp?.();
  };

  const { mutate, isLoading: isLoadingPlaceQuote } = usePlaceQuote({
    onSuccess: () => {
      toast.success(
        t('message.quotePlacedSuccess'),
        t('title.quotePlacedSuccess'),
      );
      onClose();
      onSuccess?.();
    },
    onError: async (error, variables) => {
      const resData = await refetchRfqDetail().then((res) => {
        return res.data;
      });
      if (resData?.status === RfqStatus.Quoted) {
        toast.error(t('message.quotedAlready'), t('title.quotedAlready'));

        setOpen(false);
        return;
      }

      toast.error(...formatApiErrorToast(t, error?.message));
    },
  });
  const isLoading = isLoadingPlaceQuote;

  const onSubmit: SubmitHandler<FormValues> = async (formData) => {
    if (!rfq) return;
    if (!formData.price) return;

    await mutate({
      id: String(rfq.id),
      price: formData.price,
      ...(data?.isPartialFill && { size: formData.size }),
      ...(!brokerSpreadDisabled && {
        brokerSpread: formData.brokerSpread ?? 0,
      }),
      ...(formData.settlementDate && {
        settlementDate: dayjs(formData.settlementDate).format('YYYY-MM-DD'),
      }),
    });
  };

  const renderActions = useCallback(
    (data: RfqDetail) =>
      !priceConfirmed ? (
        <Stack
          direction={{
            xs: 'column',
            md: 'row',
          }}
          spacing={2}
          justifyContent={'space-between'}
          alignItems={{
            xs: 'stretch',
            md: 'flex-end',
          }}
          sx={{
            bgcolor: (theme) => theme.palette.background.paper,
            borderRadius: 1,
          }}
        >
          {!onMobile && <div />}
          <Stack
            direction={{
              xs: 'column',
              sm: 'row',
            }}
            justifyContent={'flex-end'}
            spacing={2}
          >
            <Box>
              <Button
                variant={isFormValuesValid ? 'contained' : 'text'}
                onClick={() => {
                  if (isFormValuesValid) {
                    setPriceConfirmed(true);
                  }
                }}
                disabled={!isFormValuesValid || !ableToQuote}
                size="large"
                fullWidth
              >
                {t('action.layQuote')}
              </Button>
            </Box>
          </Stack>
        </Stack>
      ) : (
        <Stack
          direction={{
            xs: 'column',
            sm: 'row',
          }}
          justifyContent={'flex-end'}
          spacing={2}
        >
          <Button
            variant="outlined"
            onClick={() => setPriceConfirmed(false)}
            size="large"
          >
            {t('action.reselectBroker')}
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            size="large"
            data-ga-event="action_lay_quote_submit"
            data-ga-category="trade"
            data-testid="action-lay-quote-submit"
            disabled={isLoading}
          >
            {t('common:action.confirm')}
          </Button>
        </Stack>
      ),
    [priceConfirmed, isFormValuesValid, ableToQuote, t, isLoading],
  );
  const renderContent = useCallback(
    () => (
      <>
        <Box ref={headerContainerRef}>
          <RfqHeader rfq={rfq} />
        </Box>
        <Divider />
        <Box
          pl={{
            xs: 2,
            lg: 8,
          }}
          pr={{
            xs: 2,
            lg: 4,
          }}
          py={{
            xs: 2,
            lg: 4,
          }}
          maxHeight={{
            xs: `calc(90dvh - ${headerHeight}px - 4px)`,
            md: 'unset',
          }}
          overflow={{
            xs: 'auto',
            md: 'unset',
          }}
        >
          <ActionFetchRfqDetail rfqId={rfq?.id} view={1} enabled={open}>
            {({ data, isLoading: isLoadingData }) => (
              <>
                <Grid
                  container
                  rowSpacing={{
                    xs: 2,
                    md: 4,
                  }}
                  columnSpacing={2}
                >
                  {onMobile && (
                    <Grid item xs={12}>
                      <DetailLabel>{t('field.status')}</DetailLabel>
                      <TypographyWithLoading loading={isLoadingData}>
                        <ColumnStatus rfq={data} />
                      </TypographyWithLoading>
                    </Grid>
                  )}
                  <Grid item xs={6} md={4}>
                    <Stack direction={'row'} spacing={1} mb={'0.35em'}>
                      <DetailLabel>{t('field.size')}</DetailLabel>
                      {!onMobile && sizeHelperText && (
                        <InformationMark
                          title={sizeHelperText}
                          slots={{
                            icon: (
                              <SvgIcon
                                sx={{
                                  width: 16,
                                  height: 16,
                                  color: 'warning.main',
                                }}
                              >
                                <WarningTriangleIcon />
                              </SvgIcon>
                            ),
                          }}
                        />
                      )}
                    </Stack>

                    {data?.isPartialFill && !priceConfirmed && ableToQuote ? (
                      <Controller
                        name="size"
                        control={control}
                        render={({ field, fieldState }) => (
                          <InputPartialFillSize
                            rfq={data}
                            {...field}
                            error={!!fieldState.error}
                            helperText={
                              onMobile
                                ? fieldState.error?.message || sizeHelperText
                                : fieldState.error?.message
                            }
                            disabled={!ableToQuote}
                          />
                        )}
                      />
                    ) : (
                      <TypographyWithLoading loading={isLoadingData}>
                        <ColumnSize rfq={data} />
                      </TypographyWithLoading>
                    )}
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <DetailLabel>{t('field.price')}</DetailLabel>
                    <TypographyWithLoading loading={isLoadingData}>
                      {!priceConfirmed ? (
                        <Controller
                          name="price"
                          control={control}
                          render={({ field, fieldState }) => (
                            <>
                              <InputPrice
                                {...field}
                                placeholder={t('tooltip.layQuote')}
                                error={!!fieldState.error}
                                helperText={fieldState.error?.message}
                                disabled={!data?.reQuoteChance}
                              />
                              {priceHelperText && (
                                <Stack
                                  direction={'row'}
                                  spacing={1}
                                  alignItems={'center'}
                                >
                                  <SvgIcon
                                    sx={{
                                      width: 16,
                                      height: 16,
                                      color: 'warning.main',
                                    }}
                                  >
                                    <WarningTriangleIcon />
                                  </SvgIcon>
                                  <Typography
                                    variant="body2"
                                    color="warning.main"
                                    gutterBottom
                                  >
                                    {priceHelperText}
                                  </Typography>
                                </Stack>
                              )}
                            </>
                          )}
                        />
                      ) : formValues?.price ? (
                        thousandSeparated(formValues?.price)
                      ) : (
                        '-'
                      )}
                    </TypographyWithLoading>
                  </Grid>
                  {!brokerSpreadDisabled && (
                    <Grid item xs={6} md={4}>
                      <DetailLabel>{t('field.brokerSpread')}</DetailLabel>
                      {priceConfirmed ? (
                        <TypographyWithLoading loading={isLoadingData}>
                          {formValues?.brokerSpread ?? '-'}
                        </TypographyWithLoading>
                      ) : (
                        <Controller
                          name="brokerSpread"
                          control={control}
                          render={({ field, fieldState }) => (
                            <InputPrice
                              {...field}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      )}
                    </Grid>
                  )}
                  <Grid item xs={6} md={4}>
                    <DetailLabel>{t('field.settlementDate')}</DetailLabel>
                    {priceConfirmed ? (
                      dateFormat(formValues.settlementDate)
                    ) : (
                      <Controller
                        name="settlementDate"
                        control={control}
                        render={({ field, fieldState }) => (
                          <InputSettlementDate
                            {...field}
                            slotProps={{
                              textField: {
                                error: !!fieldState.error,
                                helperText: fieldState.error?.message,
                              },
                            }}
                          />
                        )}
                      />
                    )}
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <DetailLabel>{t('field.direction')}</DetailLabel>
                    {isLoadingData ? (
                      <Skeleton variant="text" width={100} />
                    ) : (
                      <ColumnDirection rfq={data} variant="chip" />
                    )}
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <DetailLabel>{t('bond:field.isin')}</DetailLabel>
                    <TypographyWithLoading loading={isLoadingData}>
                      {data?.assetIdentifier || '-'}
                    </TypographyWithLoading>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <DetailLabel>
                      {t('bond:field.tradingRestrictionType')}
                    </DetailLabel>
                    <TypographyWithLoading loading={isLoadingData}>
                      {data?.bond?.tradingRestrictionType || '-'}
                    </TypographyWithLoading>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <DetailLabel>{t('field.quoteRequestedAt')}</DetailLabel>
                    <TypographyWithLoading loading={isLoadingData}>
                      <ColumnCreatedAt rfq={data} />
                    </TypographyWithLoading>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <DetailLabel>{t('field.id')}</DetailLabel>
                    <TypographyWithLoading loading={isLoadingData}>
                      <ColumnId rfq={data} />
                    </TypographyWithLoading>
                  </Grid>
                  {!onMobile && data && (
                    <Grid item xs={12}>
                      {renderActions(data)}
                    </Grid>
                  )}
                </Grid>
                {onMobile && data && (
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: 16,
                      left: 16,
                      right: 16,
                    }}
                  >
                    {renderActions(data)}
                  </Box>
                )}
              </>
            )}
          </ActionFetchRfqDetail>
          {onMobile && <Box height={priceConfirmed ? 160 : 80} />}
        </Box>
      </>
    ),
    [
      rfq,
      open,
      onMobile,
      t,
      sizeHelperText,
      priceConfirmed,
      ableToQuote,
      renderActions,
      headerHeight,
      priceHelperText,
    ],
  );

  useEffect(() => {
    if (open) {
      onOpen?.();
    }
  }, [open]);

  useEffect(() => {
    defaultValues && reset(defaultValues);
  }, [defaultValues]);

  if (onMobile) {
    return (
      <>
        {children({
          onClick: handleClick,
          ...analyticsMetaProps,
        })}
        <ModalLayout
          open={open}
          onClose={() => {
            if (isLoading) return;
            onClose();
          }}
        >
          {renderContent()}
        </ModalLayout>
      </>
    );
  }

  return (
    <>
      {children({
        onClick: handleClick,
        ...analyticsMetaProps,
      })}
      <BaseActionDialog
        open={open}
        onClose={() => {
          if (isLoading) return;
          onClose();
        }}
        width={{
          xs: '90%',
          md: '80%',
          lg: 976,
        }}
        data-testid="dialog-lay-quote"
      >
        {renderContent()}
      </BaseActionDialog>
    </>
  );
};

export default ActionLayQuote;
