import { Typography, TypographyProps } from '@mui/material';

const DetailLabel: React.FC<TypographyProps> = ({ children, ...props }) => (
  <Typography
    variant="subtitle2"
    color="text.secondary"
    textTransform={'uppercase'}
    fontWeight={'bold'}
    gutterBottom
    {...props}
  >
    {children}
  </Typography>
);

export default DetailLabel;
