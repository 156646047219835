import React, { useMemo } from 'react';
import { RfqDetail, useRfqDetail } from '../../../services/brokers';
import { useIndex } from '../../../services/bonds';

type Bond = NonNullable<ReturnType<typeof useIndex>['data']>['content'][number];

const ActionFetchRfqDetail: React.FC<{
  children: (props: {
    data?: RfqDetail & {
      bond?: Bond;
    };
    isLoading?: boolean;
  }) => JSX.Element;
  rfqId: number;
  view: 0 | 1;
  enabled?: boolean;
}> = ({ children, rfqId, view, enabled = true }) => {
  const { data, isLoading } = useRfqDetail(
    String(rfqId),
    { view },
    {
      enabled,
      refetchOnWindowFocus: true,
      keepPreviousData: true,
      cacheTime: 0,
    },
  );
  const { data: bondIndex, isLoading: isLoadingBondDetail } = useIndex(
    {
      page: 1,
      size: 1,
      isinList: data?.assetIdentifier,
    },
    {
      enabled: Boolean(data?.assetIdentifier),
      keepPreviousData: true,
    },
  );
  const bondDetail = useMemo(() => bondIndex?.content[0], [bondIndex]);

  return (
    <>
      {children({
        data: data
          ? {
              ...data,
              bond: bondDetail,
            }
          : undefined,
        isLoading: isLoading || isLoadingBondDetail,
      })}
    </>
  );
};

export default ActionFetchRfqDetail;
