import { isNaN, isNil, isString } from 'lodash';

export function formatPrice(price: number | string | null | undefined) {
  if (isNil(price)) return null;
  if (isNaN(parseFloat(price.toString()))) return null;

  const number = isString(price) ? parseFloat(price) : price;

  return (Math.round(number * 100) / 100).toFixed(2);
}

export function formatYield(yieldValue: number | string | null | undefined) {
  if (isNil(yieldValue)) return null;
  if (isNaN(parseFloat(yieldValue.toString()))) return null;

  const number = isString(yieldValue) ? parseFloat(yieldValue) : yieldValue;

  return (Math.round(number * 100) / 100).toFixed(2);
}

export function formatCoupon(coupon: number | string | null | undefined) {
  if (isNil(coupon)) return null;
  if (isNaN(parseFloat(coupon.toString()))) return null;

  const number = isString(coupon) ? parseFloat(coupon) : coupon;

  return number;
}

export function thousandSeparated(number: number | string | null | undefined) {
  if (isNil(number)) return null;
  if (isNaN(parseFloat(number.toString()))) return null;

  const num = isString(number) ? parseFloat(number) : number;

  const integer = Math.floor(num);
  const decimal = number.toString().split('.')[1];

  return `${integer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}${
    decimal ? `.${decimal}` : ''
  }`;
}

export function decimalPoint(numb: string, indexPoint = 2): string {
  return parseFloat(`${numb}`).toFixed(indexPoint);
}

export function numberSeparation(num: number, precision: number = 2) {
  const decimal = parseFloat(decimalPoint(String(num), precision));
  return decimal.toLocaleString('en-US', {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  });
}

export function formatNumberUnit(number: number | string | null | undefined) {
  if (isNil(number)) return null;
  if (isNaN(parseFloat(number.toString()))) return null;

  const value = isString(number) ? parseFloat(number) : number;

  const scale = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
    { value: 1e21, symbol: 'Z' },
    { value: 1e24, symbol: 'Y' },
  ];
  const i = scale.findIndex((item) => value < item.value);

  if (i === 0) {
    return thousandSeparated(value);
  }

  if (i === -1) {
    return thousandSeparated(value);
  }

  return (
    thousandSeparated(Number((value / scale[i - 1].value).toFixed())) +
    scale[i - 1].symbol
  );
}
